import React, { Component } from "react"
import { useNavigate, Link, useOutletContext } from "react-router-dom";
import { Editor } from '@tinymce/tinymce-react';
import { Dropdown, DropdownButton, Button, FormControl, Row, Col, Form } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Upload } from '@aws-sdk/lib-storage';
import { S3Client, DeleteObjectCommand } from '@aws-sdk/client-s3';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Loading from "../../Components/Loading";
import { Api } from "../../AWS/aws"
import listExtension from "../../Assets/Js/listExtension"
import Swal from 'sweetalert2'
import "./CreateProject.css"

function withParams(Component) {
    return props => <Component {...props} params={useOutletContext()} navigate={useNavigate()} />;
}
class CreateProject extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: Date.now(),
            id: false,
            isSaving: false,
            isLoadingDescription: true,
            isLoadingCourses: true,
            isLoadingStudent: false,
            isLoadingImage: false,
            filterStudent: false,
            filter: '',
            viewId: false,
            editorRefDetalle: React.createRef(null),
            editorRefEstructura: React.createRef(null),
            editorRefEjemplos: React.createRef(null),
            editorRefPauta: React.createRef(null),
            editorRefContenidos: React.createRef(null),
            listStudents: [],
            courses: [],
            students: [],
            nroStudents: 0,
            asignatura: "Seleccione...",
            idAsignatura: '',
            modalidad: "Seleccione...",
            fechaEntregas: [],
            entregas: 1,
            fechaEntregaAvances: [],
            nombre: "",
            integrantes: 2,
            avances: 0,
            detalle: "",
            estructura: "",
            ejemplos: "",
            pauta: "",
            contenidos: "",
            filesDetalle: [],
            loadingFileDetalle: [],
            parallelUploads3Detalle: [],
            filesEstructura: [],
            loadingFileEstructura: [],
            parallelUploads3Estructura: [],
            filesEjemplos: [],
            loadingFileEjemplos: [],
            parallelUploads3Ejemplos: [],
            filesPauta: [],
            loadingFilePauta: [],
            parallelUploads3Pauta: [],
            filesContenidos: [],
            loadingFileContenidos: [],
            parallelUploads3Contenidos: [],
            imagen: null,
            loadingImagen: null,
            parallelUploads3Img: null,
            arrGroups: [[]],
            gruposAutomaticos: true
        };
    }

    getCourses() {
        return Api({
            path: "/teacher/courses"
        })
    }

    getStudents(course) {
        return Api({
            path: `/teacher/students/${course}`
        })
    }


    postProject(body) {
        return Api({
            path: `/teacher/project`,
            body: body,
            method: 'POST'
        })
    }
    async componentDidMount() {

        if (this.props.idProject) {

        } else {
            this.setState({ isLoadingDescription: false })
            const response_courses = await this.getCourses()
            if (response_courses.code === 0) {
                this.setState({ isLoadingCourses: false, courses: response_courses.body })
            } else {
                this.setState({ isLoadingCourses: false })
            }

        }
    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    save = async () => {
        this.setState({ isSaving: true })
        if (!this.state.nombre) {
            Swal.fire({
                icon: 'error',
                text: "No puede dejar el nombre en blanco",
                confirmButtonColor: "#dc3545"
            })
            this.setState({ isSaving: false })
            return
        }
        if (this.state.idAsignatura === "") {
            Swal.fire({
                icon: 'error',
                text: "Tiene que seleccionar una asignatura",
                confirmButtonColor: "#dc3545"
            })
            this.setState({ isSaving: false })
            return
        }

        
        if (this.state.modalidad === "Seleccione...") {
            Swal.fire({
                icon: 'error',
                text: "Tiene que seleccionar una modalidad",
                confirmButtonColor: "#dc3545"
            })
            this.setState({ isSaving: false })
            return
        }

        if (this.state.fechaEntregas.length === 0) {
            Swal.fire({
                icon: 'error',
                text: "Tiene que seleccionar almenos una fecha de entrega",
                confirmButtonColor: "#dc3545"
            })
            this.setState({ isSaving: false })
            return
        }

        let detalle = this.state.editorRefDetalle.current.getContent()
        let estructura = this.state.editorRefEstructura.current.getContent()
        let ejemplos = this.state.editorRefEjemplos.current.getContent()
        let pauta = this.state.editorRefPauta.current.getContent()
        let contenidos = this.state.editorRefContenidos.current.getContent()

        let fechaEntregaAvances = this.state.fechaEntregaAvances
        for (let index = 0; index < this.state.avances; index++) {
            if (!fechaEntregaAvances[index]) {
                fechaEntregaAvances[index] = " "
            }

        }

        let students = []

        this.state.students.forEach(res => {
            students.push(res.id)
        })

        let body = {
            id: this.state.id,
            students: students,
            listStudents: this.state.listStudents,
            nroStudents: this.state.nroStudents,
            idAsignatura: this.state.idAsignatura,
            modalidad: this.state.modalidad,
            fechaEntregas: this.state.fechaEntregas,
            entregas: this.state.entregas,
            fechaEntregaAvances: fechaEntregaAvances,
            nombre: this.state.nombre,
            integrantes: this.state.integrantes,
            avances: this.state.avances,
            arrGroups: this.state.arrGroups,
            gruposAutomaticos: this.state.gruposAutomaticos,
            detalle: detalle,
            estructura: estructura,
            ejemplos: ejemplos,
            pauta: pauta,
            contenidos: contenidos,
            filesDetalle: this.state.filesDetalle,
            filesEstructura: this.state.filesEstructura,
            filesEjemplos: this.state.filesEjemplos,
            filesPauta: this.state.filesPauta,
            filesContenidos: this.state.filesContenidos,
            imagen: this.state.imagen
        }

        this.postProject(body).then(res => {

            Swal.fire({
                icon: 'success',
                text: 'Proyecto ' + this.state.nombre + ' creado exitosamente!',
                confirmButtonColor: "#ffc107"
            })

            this.setState({ isSaving: false })
            this.props.navigate("/")
        })

    }

    onClickIconSearch = () => {
        this.setState({ filterStudent: !this.state.filterStudent, filter: '' })
    }

    onChange(index, event) {
        let arr = this.state.fechaEntregaAvances;
        arr[index] = event.target.value
        this.setState({ fechaEntregaAvances: arr });

    }

    onChangeEntrega(index, event) {
        let arr = this.state.fechaEntregas;
        arr[index] = event.target.value
        this.setState({ fechaEntregas: arr });

    }

    onChangeNroIntegrantes = (event) => {
        if (event.target.value >= 2) {

            let arrGroups = []
            let nro = Math.round(this.state.nroStudents / event.target.value)
            if (nro < (this.state.nroStudents / event.target.value)) {
                nro += 1
            }
            for (let index = 0; index < nro; index++) {
                arrGroups[index] = []
            }
            this.setState({ integrantes: parseInt(event.target.value, 10), arrGroups: arrGroups, students: [].concat(this.state.listStudents) })
        }

    }

    onClickCourse = async (course) => {
        this.setState({ asignatura: course.name, idAsignatura: course.id, isLoadingStudent: true })
        const res = await this.getStudents(course.id)
        if (res.code === 0) {
            let arrGroups = []
            let nro = Math.round(res.body.length / this.state.integrantes)
            if (nro < (res.body.length / this.state.integrantes)) {
                nro += 1
            }
            for (let index = 0; index < nro; index++) {
                arrGroups[index] = []

            }

            let students = []
            this.setState({ students: res.body, listStudents: students.concat(res.body), nroStudents: res.body.length, arrGroups: arrGroups, isLoadingStudent: false })
        }
    }

    onDragEnd = (result) => {
        if (!this.state.isSaving) {
            const { destination, source } = result
            if (!destination) {
                return
            }
            if (destination.index === source.index && destination.droppableId === source.droppableId) {
                return
            }

            let arrGroups = this.state.arrGroups
            let arrStudent = this.state.students

            if (source.droppableId === "droppable student" && destination.droppableId !== source.droppableId) {

                let arr = destination.droppableId.split(" ")
                let i = parseInt(arr[2], 10);

                let aux = arrStudent[source.index]
                arrStudent.splice(source.index, 1)
                arrGroups[i].splice(destination.index, 0, aux)

            } if (source.droppableId === "droppable student" && destination.droppableId === source.droppableId) {

                let aux = arrStudent[source.index]
                arrStudent.splice(source.index, 1)
                arrStudent.splice(destination.index, 0, aux)


            } if (destination.droppableId === "droppable student" && destination.droppableId !== source.droppableId) {

                let arr = source.droppableId.split(" ")
                let i = parseInt(arr[2], 10);
                let j = source.index

                let aux = arrGroups[i][j]
                arrGroups[i].splice(j, 1)
                if (!arrStudent) {
                    arrStudent = []
                }
                arrStudent.splice(destination.index, 0, aux)

            } if (destination.droppableId !== "droppable student" && source.droppableId !== "droppable student" && destination.droppableId !== source.droppableId) {

                let arr = destination.droppableId.split(" ")
                let iDestination = parseInt(arr[2], 10);

                arr = source.droppableId.split(" ")
                let iSource = parseInt(arr[2], 10);
                let jSource = source.index

                let aux = arrGroups[iSource][jSource]
                arrGroups[iSource].splice(jSource, 1)
                if (!arrGroups[iDestination]) {
                    arrGroups[iDestination] = []
                }
                arrGroups[iDestination].splice(destination.index, 0, aux)

            } if (destination.droppableId !== "droppable student" && source.droppableId !== "droppable student" && destination.droppableId === source.droppableId) {

                let arr = destination.droppableId.split(" ")
                let iDestination = parseInt(arr[2], 10);

                let aux = arrGroups[iDestination][source.index]
                arrGroups[iDestination].splice(source.index, 1)
                arrGroups[iDestination].splice(destination.index, 0, aux)

            }

            this.setState({ arrGroups: arrGroups, students: arrStudent })

        }
    }

    onClickModalidad = (modalidad) => {
        if (modalidad === "Grupal") {
            let arrGroups = []
            let nro = Math.round(this.state.nroStudents / this.state.integrantes)
            if (nro < (this.state.nroStudents / this.state.integrantes)) {
                nro += 1
            }
            for (let index = 0; index < nro; index++) {
                arrGroups[index] = []
            }
            this.setState({ modalidad: modalidad, gruposAutomaticos: true, arrGroups: arrGroups })
        } else {
            this.setState({ modalidad: modalidad, students: [].concat(this.state.listStudents) })
        }

    }

    uploadS3 = async (file, key, fun, from) => {

        const target = {
            Bucket: "tallervirtualarchivos",
            Key: key,
            Body: file,
            ACL: "public-read",
            ContentType: file.type
        }
        const creds = {
            accessKeyId: "AKIA2T5OXYWGGXOKREU2",
            secretAccessKey: "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",
        }
        try {
            const parallelUploads3 = new Upload({
                client: new S3Client({
                    region: "us-east-1",
                    credentials: creds
                }),
                partSize: 1024 * 1024 * 20,
                params: target,
            })

            if (from === "Detalle") {
                let arr = this.state.parallelUploads3Detalle
                let pos = this.state.filesDetalle.indexOf(key)
                if (pos !== -1) {
                    arr[pos] = parallelUploads3
                    this.setState({ parallelUploads3Detalle: arr })
                }
            }
            if (from === "Contenidos") {
                let arr = this.state.parallelUploads3Contenidos
                let pos = this.state.filesContenidos.indexOf(key)
                if (pos !== -1) {
                    arr[pos] = parallelUploads3
                    this.setState({ parallelUploads3Contenidos: arr })
                }
            }
            if (from === "Ejemplos") {
                let arr = this.state.parallelUploads3Ejemplos
                let pos = this.state.filesEjemplos.indexOf(key)
                if (pos !== -1) {
                    arr[pos] = parallelUploads3
                    this.setState({ parallelUploads3Ejemplos: arr })
                }
            }
            if (from === "Estructura") {
                let arr = this.state.parallelUploads3Estructura
                let pos = this.state.filesEstructura.indexOf(key)
                if (pos !== -1) {
                    arr[pos] = parallelUploads3
                    this.setState({ parallelUploads3Estructura: arr })
                }
            }
            if (from === "Pauta") {
                let arr = this.state.parallelUploads3Pauta
                let pos = this.state.filesPauta.indexOf(key)
                if (pos !== -1) {
                    arr[pos] = parallelUploads3
                    this.setState({ parallelUploads3Pauta: arr })
                }
            }
            if (from === "Img") {
                this.setState({ parallelUploads3Img: parallelUploads3 })
            }

            parallelUploads3.on("httpUploadProgress", (process) => {
                fun(process)
            })

            await parallelUploads3.done()
            return true
        } catch (e) {
            console.log(e);
            return false
        }
    }

    deleteS3 = (key) => {

        const target = {
            Bucket: "tallervirtualarchivos",
            Key: key
        }

        const creds = {
            accessKeyId: "AKIA2T5OXYWGGXOKREU2",
            secretAccessKey: "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",
        }

        const client = new S3Client({
            region: "us-east-1",
            credentials: creds
        })

        const command = new DeleteObjectCommand(target)

        client.send(command).then((res) => {
            console.log(res);
        })
    }

    deleteDetalle = (key, i) => {

        if (this.state.loadingFileDetalle[i]) {
            if (this.state.loadingFileDetalle[i].processing) {
                //se esta cargando archivo
                let arr = this.state.parallelUploads3Detalle
                arr[i].abort()
                arr.splice(i, 1)
                this.setState({ parallelUploads3Detalle: arr })
            } else {
                if (this.state.loadingFileDetalle[i].value === this.state.loadingFileDetalle[i].total) {
                    //ya se cargo el archivo
                    this.deleteS3(key)
                } else {
                    //se esta cargando archivo
                    let arr = this.state.parallelUploads3Detalle
                    arr[i].abort()
                    arr.splice(i, 1)
                    this.setState({ parallelUploads3Detalle: arr })
                }
            }
        } else {

        }
    }

    deleteContenidos = (key, i) => {

        if (this.state.loadingFileContenidos[i]) {
            if (this.state.loadingFileContenidos[i].processing) {
                //se esta cargando archivo
                let arr = this.state.parallelUploads3Contenidos
                arr[i].abort()
                arr.splice(i, 1)
                this.setState({ parallelUploads3Contenidos: arr })
            } else {
                if (this.state.loadingFileContenidos[i].value === this.state.loadingFileContenidos[i].total) {
                    //ya se cargo el archivo
                    this.deleteS3(key)
                } else {
                    //se esta cargando archivo
                    let arr = this.state.parallelUploads3Contenidos
                    arr[i].abort()
                    arr.splice(i, 1)
                    this.setState({ parallelUploads3Contenidos: arr })
                }
            }
        } else {

        }
    }

    deleteEjemplos = (key, i) => {

        if (this.state.loadingFileEjemplos[i]) {
            if (this.state.loadingFileEjemplos[i].processing) {
                //se esta cargando archivo
                let arr = this.state.parallelUploads3Ejemplos
                arr[i].abort()
                arr.splice(i, 1)
                this.setState({ parallelUploads3Ejemplos: arr })
            } else {
                if (this.state.loadingFileEjemplos[i].value === this.state.loadingFileEjemplos[i].total) {
                    //ya se cargo el archivo
                    this.deleteS3(key)
                } else {
                    //se esta cargando archivo
                    let arr = this.state.parallelUploads3Ejemplos
                    arr[i].abort()
                    arr.splice(i, 1)
                    this.setState({ parallelUploads3Ejemplos: arr })
                }
            }
        } else {

        }
    }

    deleteEstructura = (key, i) => {

        if (this.state.loadingFileEstructura[i]) {
            if (this.state.loadingFileEstructura[i].processing) {
                //se esta cargando archivo
                let arr = this.state.parallelUploads3Estructura
                arr[i].abort()
                arr.splice(i, 1)
                this.setState({ parallelUploads3Estructura: arr })
            } else {
                if (this.state.loadingFileEstructura[i].value === this.state.loadingFileEstructura[i].total) {
                    //ya se cargo el archivo
                    this.deleteS3(key)
                } else {
                    //se esta cargando archivo
                    let arr = this.state.parallelUploads3Estructura
                    arr[i].abort()
                    arr.splice(i, 1)
                    this.setState({ parallelUploads3Estructura: arr })
                }
            }
        } else {

        }
    }

    deletePauta = (key, i) => {

        if (this.state.loadingFilePauta[i]) {
            if (this.state.loadingFilePauta[i].processing) {
                //se esta cargando archivo
                let arr = this.state.parallelUploads3Pauta
                arr[i].abort()
                arr.splice(i, 1)
                this.setState({ parallelUploads3Pauta: arr })
            } else {
                if (this.state.loadingFilePauta[i].value === this.state.loadingFilePauta[i].total) {
                    //ya se cargo el archivo
                    this.deleteS3(key)
                } else {
                    //se esta cargando archivo
                    let arr = this.state.parallelUploads3Pauta
                    arr[i].abort()
                    arr.splice(i, 1)
                    this.setState({ parallelUploads3Pauta: arr })
                }
            }
        } else {

        }
    }

    deleteImg = (key) => {

        if (this.state.loadingImagen) {
            if (this.state.loadingImagen.processing) {
                //se esta cargando archivo
                let parallelUploads3Img = this.state.parallelUploads3Img
                parallelUploads3Img.abort()
                parallelUploads3Img = null
                this.setState({ parallelUploads3Img: parallelUploads3Img })
            } else {
                if (this.state.loadingImagen.value === this.state.loadingImagen.total) {
                    //ya se cargo el archivo
                    this.deleteS3(key)
                } else {
                    //se esta cargando archivo
                    let parallelUploads3Img = this.state.parallelUploads3Img
                    parallelUploads3Img.abort()
                    parallelUploads3Img = null
                    this.setState({ parallelUploads3Img: parallelUploads3Img })
                }
            }
        } else {

        }
    }

    filesDetalle = (eventFiles) => {
        let date = this.state.date
        let files = this.state.filesDetalle
        let teacher = this.props.user

        for (let index = 0; index < eventFiles.length; index++) {
            let key = teacher.id + "/" + date + "/detalle/" + eventFiles[index].name
            if (files.indexOf(key) < 0) {
                files.push(key)
                let loadingFileDetalle = this.state.loadingFileDetalle

                loadingFileDetalle[files.indexOf(key)] = { processing: true }
                this.setState({ loadingFileDetalle: loadingFileDetalle })

                this.uploadS3(eventFiles[index], key, (data) => {
                    let i = files.indexOf(key)
                    let loadingFileDetalle = this.state.loadingFileDetalle
                    loadingFileDetalle[i] = { value: data.loaded, total: data.total }
                    this.setState({ loadingFileDetalle: loadingFileDetalle })
                }, "Detalle").then((res) => {
                    console.log(res);
                })
            }
        }

        this.setState({ filesDetalle: files })
    }

    filesContenidos = (eventFiles) => {
        let date = this.state.date
        let files = this.state.filesContenidos
        let teacher = this.props.user

        for (let index = 0; index < eventFiles.length; index++) {
            let key = teacher.id + "/" + date + "/contenidos/" + eventFiles[index].name
            if (files.indexOf(key) < 0) {
                files.push(key)
                let loadingFileContenidos = this.state.loadingFileContenidos

                loadingFileContenidos[files.indexOf(key)] = { processing: true }
                this.setState({ loadingFileContenidos: loadingFileContenidos })

                this.uploadS3(eventFiles[index], key, (data) => {
                    let i = files.indexOf(key)
                    let loadingFileContenidos = this.state.loadingFileContenidos
                    loadingFileContenidos[i] = { value: data.loaded, total: data.total }
                    this.setState({ loadingFileContenidos: loadingFileContenidos })
                }, "Contenidos").then((res) => {
                    console.log(res);
                })
            }
        }

        this.setState({ filesContenidos: files })
    }

    filesEjemplos = (eventFiles) => {
        let date = this.state.date
        let files = this.state.filesEjemplos
        let teacher = this.props.user

        for (let index = 0; index < eventFiles.length; index++) {
            let key = teacher.id + "/" + date + "/ejemplos/" + eventFiles[index].name
            if (files.indexOf(key) < 0) {
                files.push(key)
                let loadingFileEjemplos = this.state.loadingFileEjemplos

                loadingFileEjemplos[files.indexOf(key)] = { processing: true }
                this.setState({ loadingFileEjemplos: loadingFileEjemplos })

                this.uploadS3(eventFiles[index], key, (data) => {
                    let i = files.indexOf(key)
                    let loadingFileEjemplos = this.state.loadingFileEjemplos
                    loadingFileEjemplos[i] = { value: data.loaded, total: data.total }
                    this.setState({ loadingFileEjemplos: loadingFileEjemplos })
                }, "Ejemplos").then((res) => {
                    console.log(res);
                })
            }
        }

        this.setState({ filesEjemplos: files })
    }

    filesEstructura = (eventFiles) => {
        let date = this.state.date
        let files = this.state.filesEstructura
        let teacher = this.props.user

        for (let index = 0; index < eventFiles.length; index++) {
            let key = teacher.id + "/" + date + "/estructura/" + eventFiles[index].name
            if (files.indexOf(key) < 0) {
                files.push(key)
                let loadingFileEstructura = this.state.loadingFileEstructura

                loadingFileEstructura[files.indexOf(key)] = { processing: true }
                this.setState({ loadingFileEstructura: loadingFileEstructura })

                this.uploadS3(eventFiles[index], key, (data) => {
                    let i = files.indexOf(key)
                    let loadingFileEstructura = this.state.loadingFileEstructura
                    loadingFileEstructura[i] = { value: data.loaded, total: data.total }
                    this.setState({ loadingFileEstructura: loadingFileEstructura })
                }, "Estructura").then((res) => {
                    console.log(res);
                })
            }
        }

        this.setState({ filesEstructura: files })
    }

    filesPauta = (eventFiles) => {
        let date = this.state.date
        let files = this.state.filesPauta
        let teacher = this.props.user

        for (let index = 0; index < eventFiles.length; index++) {
            let key = teacher.id + "/" + date + "/pauta/" + eventFiles[index].name
            if (files.indexOf(key) < 0) {
                files.push(key)
                let loadingFilePauta = this.state.loadingFilePauta

                loadingFilePauta[files.indexOf(key)] = { processing: true }
                this.setState({ loadingFilePauta: loadingFilePauta })

                this.uploadS3(eventFiles[index], key, (data) => {
                    let i = files.indexOf(key)
                    let loadingFilePauta = this.state.loadingFilePauta
                    loadingFilePauta[i] = { value: data.loaded, total: data.total }
                    this.setState({ loadingFilePauta: loadingFilePauta })
                }, "Pauta").then((res) => {
                    console.log(res);
                })
            }
        }

        this.setState({ filesPauta: files })
    }

    renderImgFileDetalle(ruta, index) {

        if (this.state.loadingFileDetalle[index]) {
            if (this.state.loadingFileDetalle[index].processing) {
                return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
            } else {
                if (this.state.loadingFileDetalle[index].value === this.state.loadingFileDetalle[index].total) {
                    return (<img width={50} src={ruta} alt="archivo" />)
                } else {
                    return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
                }
            }
        } else {
            return (<img width={50} src={ruta} alt="archivo" />)
        }

    }

    renderImgFileEstructura(ruta, index) {

        if (this.state.loadingFileEstructura[index]) {
            if (this.state.loadingFileEstructura[index].processing) {
                return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
            } else {
                if (this.state.loadingFileEstructura[index].value === this.state.loadingFileEstructura[index].total) {
                    return (<img width={50} src={ruta} alt="archivo" />)
                } else {
                    return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
                }
            }
        } else {
            return (<img width={50} src={ruta} alt="archivo" />)
        }

    }

    renderImgFileContenidos(ruta, index) {

        if (this.state.loadingFileContenidos[index]) {
            if (this.state.loadingFileContenidos[index].processing) {
                return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
            } else {
                if (this.state.loadingFileContenidos[index].value === this.state.loadingFileContenidos[index].total) {
                    return (<img width={50} src={ruta} alt="archivo" />)
                } else {
                    return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
                }
            }
        } else {
            return (<img width={50} src={ruta} alt="archivo" />)
        }

    }

    renderImgFileEjemplos(ruta, index) {

        if (this.state.loadingFileEjemplos[index]) {
            if (this.state.loadingFileEjemplos[index].processing) {
                return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
            } else {
                if (this.state.loadingFileEjemplos[index].value === this.state.loadingFileEjemplos[index].total) {
                    return (<img width={50} src={ruta} alt="archivo" />)
                } else {
                    return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
                }
            }
        } else {
            return (<img width={50} src={ruta} alt="archivo" />)
        }

    }

    renderImgFilePauta(ruta, index) {

        if (this.state.loadingFilePauta[index]) {
            if (this.state.loadingFilePauta[index].processing) {
                return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
            } else {
                if (this.state.loadingFilePauta[index].value === this.state.loadingFilePauta[index].total) {
                    return (<img width={50} src={ruta} alt="archivo" />)
                } else {
                    return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
                }
            }
        } else {
            return (<img width={50} src={ruta} alt="archivo" />)
        }

    }

    render() {
        let filesDetalle = this.state.filesDetalle
        let loadingFileDetalle = this.state.loadingFileDetalle

        let filesEstructura = this.state.filesEstructura
        let loadingFileEstructura = this.state.loadingFileEstructura

        let filesContenidos = this.state.filesContenidos
        let loadingFileContenidos = this.state.loadingFileContenidos

        let filesEjemplos = this.state.filesEjemplos
        let loadingFileEjemplos = this.state.loadingFileEjemplos

        let filesPauta = this.state.filesPauta
        let loadingFilePauta = this.state.loadingFilePauta


        let imagen = this.state.imagen

        let students = this.state.students
        let arrGroups = this.state.arrGroups

        let arr = []
        arr.length = this.state.avances
        arr.fill("")
        for (let index = 0; index < this.state.fechaEntregaAvances.length; index++) {
            if (index > arr.length - 1) {
                break
            }
            if (this.state.fechaEntregaAvances[index]) {
                arr[index] = this.state.fechaEntregaAvances[index]
            }
        }

        let arrEntregas = []
        arrEntregas.length = this.state.entregas
        arrEntregas.fill("")
        for (let index = 0; index < this.state.fechaEntregas.length; index++) {
            if (index > arrEntregas.length - 1) {
                break
            }
            if (this.state.fechaEntregas[index]) {
                arrEntregas[index] = this.state.fechaEntregas[index]
            }
        }

        return (
            <div className="CreateProject">
                <h1 className="titulo animate__animated animate__fadeInDown">Creación de proyecto</h1>
                <Row className="contenedor animate__animated animate__fadeInLeft">

                    <div className='mb-3'>
                        <Row className="justify-content-end">
                            <Col xs={3} sm={2} lg={2} xl={2}><Button variant="secondary" onClick={this.save} disabled={this.state.isSaving} >Guardar</Button></Col>
                            <Col xs={3} sm={2} lg={2} xl={2}><Link className={this.state.isSaving ? "not-active btn btn-danger " : "btn btn-danger "} to="/" >Cancelar</Link></Col>
                        </Row>
                    </div>

                    <Col lg={5} md={6} className='mb-5'>
                        <h3 className="sub-titulo">Nombre proyecto </h3>
                        {this.state.isLoadingDescription ?
                            <Loading />
                            :
                            <FormControl value={this.state.nombre} onChange={(event) => this.setState({ nombre: event.target.value })} style={{ width: "330px" }} disabled={this.state.isSaving} />
                        }
                    </Col>

                    {imagen ?
                        <div className='mb-5'>
                            <h3 className="sub-titulo">Imagen del proyecto </h3>
                            <Row>
                                <Col className="col-file">
                                    <Row className="card-file p-2">
                                        <FontAwesomeIcon
                                            onClick={(e) => {
                                                this.deleteImg(this.state.imagen)
                                                this.setState({ imagen: null })
                                            }}
                                            className="col-delete"
                                            icon={faXmark}
                                        />
                                        {this.state.isLoadingImage ?
                                            this.state.loadingImagen ?
                                                this.state.loadingImagen.processing ?
                                                    <div style={{ width: 100 }}>
                                                        <CircularProgressbar
                                                            value={0}
                                                            text={`Cargando`}
                                                            styles={
                                                                buildStyles({
                                                                    textColor: "#6c757d",
                                                                    pathColor: "#6c757d"
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    :
                                                    <div style={{ width: 100 }}>
                                                        <CircularProgressbar
                                                            value={this.state.loadingImagen.value}
                                                            maxValue={this.state.loadingImagen.total}
                                                            text={`${Math.round(this.state.loadingImagen.value * 100 / this.state.loadingImagen.total)}%`}
                                                            styles={
                                                                buildStyles({
                                                                    textColor: "#6c757d",
                                                                    pathColor: "#6c757d"
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                :
                                                <>alo</>

                                            :
                                            <img width={400} src={"https://tallervirtualarchivos.s3.amazonaws.com/" + imagen} alt="archivo" />
                                        }

                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        :
                        <div className='mb-5'>
                            <h3 className="sub-titulo">Imagen del proyecto </h3>
                            {this.state.isLoadingDescription ?
                                <Loading />
                                :
                                <div
                                    className="drop-area"
                                    id="drop-area-imagen"
                                    onDragOver={(e) => {
                                        e.preventDefault()
                                        let dropArea = document.getElementById("drop-area-imagen")
                                        dropArea.querySelector("h4").textContent = "Suelta para subir una imagen"
                                        dropArea.classList.add("active")
                                    }}
                                    onDragLeave={(e) => {
                                        e.preventDefault()
                                        let dropArea = document.getElementById("drop-area-imagen")
                                        dropArea.querySelector("h4").textContent = "Arrastra una imagen"
                                        dropArea.classList.remove("active")
                                    }}
                                    onDrop={(e) => {
                                        e.preventDefault()
                                        let dropArea = document.getElementById("drop-area-imagen")
                                        dropArea.querySelector("h4").textContent = "Arrastra una imagen"
                                        if (e.dataTransfer.files[0]) {
                                            if (e.dataTransfer.files[0].type.includes("image/")) {

                                                let date = this.state.date
                                                let teacher = this.props.user
                                                let key = teacher.id + "/" + date + "/img/" + e.dataTransfer.files[0].name

                                                this.setState({ imagen: key, isLoadingImage: true, loadingImagen: { processing: true } })

                                                this.uploadS3(e.dataTransfer.files[0], key, (data) => {
                                                    this.setState({ loadingImagen: { value: data.loaded, total: data.total } })
                                                }, "Img").then((res) => {
                                                    console.log(res);
                                                    this.setState({ isLoadingImage: false })
                                                })

                                            }
                                        }
                                        dropArea.classList.remove("active")
                                    }}
                                >
                                    <h4>Arrastra aquí una imagen</h4>
                                    <span>O</span>
                                    <Button
                                        className="mt-2"
                                        variant="secondary"
                                        disabled={this.state.isSaving}
                                        onClick={(e) => {
                                            let input = document.getElementById("inputFileImagen")
                                            input.click()
                                        }}
                                    >
                                        Selecciona una imagen
                                    </Button>
                                    <input
                                        id="inputFileImagen"
                                        type="file"
                                        hidden
                                        value={""}
                                        accept="image/*"
                                        onChange={(e) => {
                                            let dropArea = document.getElementById("drop-area-imagen")
                                            dropArea.classList.add("active")
                                            if (e.target.files[0]) {
                                                let date = this.state.date
                                                let teacher = this.props.user
                                                let key = teacher.id + "/" + date + "/img/" + e.target.files[0].name
                                                this.setState({ imagen: key, isLoadingImage: true, loadingImagen: { processing: true } })

                                                this.uploadS3(e.target.files[0], key, (data) => {
                                                    this.setState({ loadingImagen: { value: data.loaded, total: data.total } })
                                                }, "Img").then((res) => {
                                                    this.setState({ isLoadingImage: false })
                                                })

                                            }

                                            dropArea.classList.remove("active")
                                        }}
                                        disabled={this.state.isSaving}
                                    />
                                </div>
                            }
                        </div>}

                    <Col lg={5} md={6} className='mb-5'>
                        <h3 className="sub-titulo">Asignatura </h3>
                        {this.state.isLoadingCourses ?
                            <Loading />
                            :
                            <DropdownButton
                                variant="ligth"
                                title={this.state.asignatura}
                                disabled={this.state.isSaving}
                            >
                                {this.state.courses.map((course, index) => (
                                    <Dropdown.Item key={"item " + index} onClick={this.onClickCourse.bind(this, course)}>{course.name}</Dropdown.Item>
                                ))}
                            </DropdownButton>
                        }
                    </Col>

                    <Col lg={5} md={6} className='mb-5'>
                        <h3 className="sub-titulo">Modalidad </h3>
                        {this.state.isLoadingDescription ?
                            <Loading />
                            :
                            <>
                                {this.state.asignatura === "Seleccione..." ?
                                    <DropdownButton
                                        variant="ligth"
                                        title={this.state.modalidad}
                                        disabled
                                    >

                                    </DropdownButton>
                                    :
                                    <DropdownButton
                                        variant="ligth"
                                        title={this.state.modalidad}
                                        disabled={this.state.isSaving}
                                    >
                                        <Dropdown.Item onClick={this.onClickModalidad.bind(this, "Individual")}>Individual</Dropdown.Item>
                                        <Dropdown.Item onClick={this.onClickModalidad.bind(this, "Grupal")}>Grupal</Dropdown.Item>
                                    </DropdownButton>}

                            </>


                        }
                    </Col>

                    {this.state.modalidad === "Grupal" &&
                        <>
                            <Col lg={5} md={6} className='mb-5'>
                                <h3 className="sub-titulo">Nro integrantes </h3>

                                <FormControl type="number" value={this.state.integrantes} onChange={this.onChangeNroIntegrantes} style={{ width: "150px" }} disabled={this.state.isSaving} />
                            </Col>
                            <Col lg={5} md={6} className='mb-5'>
                                <h3 className="sub-titulo">Grupos </h3>
                                <Form.Check
                                    type="checkbox"
                                    id={`default-checkbox`}
                                    label={`Automatico`}
                                    checked={this.state.gruposAutomaticos}
                                    onChange={(e) => { this.setState({ gruposAutomaticos: !this.state.gruposAutomaticos }) }}
                                    disabled={this.state.isSaving}
                                />


                            </Col>

                            {!this.state.gruposAutomaticos ?
                                <>
                                    <Col lg={5} md={6} className='mb-5'>
                                        <h3 className="sub-titulo">Alumnos </h3>
                                        {!this.state.gruposAutomaticos ?
                                            <>
                                                <Form.Check
                                                    type="radio"
                                                    className="ms-3"
                                                    id={`default-rut-radio`}
                                                    label={`Ver alumnos por Rut`}
                                                    checked={this.state.viewId}
                                                    onChange={(e) => { this.setState({ viewId: true }) }}
                                                    disabled={this.state.isSaving}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    className="mb-2 ms-3"
                                                    id={`default-rut-radio`}
                                                    label={`Ver alumnos por Nombre`}
                                                    checked={!this.state.viewId}
                                                    onChange={(e) => { this.setState({ viewId: false }) }}
                                                    disabled={this.state.isSaving}
                                                />
                                            </>
                                            :
                                            <></>
                                        }

                                    </Col>
                                    <div className='mb-5'>
                                        {this.state.isLoadingStudent ?
                                            <Loading />
                                            :
                                            <DragDropContext onDragEnd={this.onDragEnd}>
                                                <Row className="row-groups-and-list">
                                                    <Col className="col-groups">
                                                        <Row className="row-groups">
                                                            {arrGroups.map((grupo, index) => (
                                                                <Col className="col-group" lg={5} key={"group " + index}>
                                                                    <h5>Grupo {index + 1}</h5>
                                                                    <Droppable droppableId={"droppable group " + index}>
                                                                        {(droppableProvided) => <ul {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                                                                            {grupo.map((student, index) => (
                                                                                <Draggable key={student.id} draggableId={student.id} index={index}>
                                                                                    {(DraggableProvided) => <li {...DraggableProvided.dragHandleProps} ref={DraggableProvided.innerRef} {...DraggableProvided.draggableProps}>
                                                                                        {this.state.viewId ?
                                                                                            student.id
                                                                                            :
                                                                                            student.name}
                                                                                    </li>}
                                                                                </Draggable>

                                                                            ))}
                                                                            {droppableProvided.placeholder}
                                                                        </ul>}
                                                                    </Droppable>

                                                                </Col>
                                                            ))}
                                                        </Row>

                                                    </Col>
                                                    <Col className="col-list" xs={5}>
                                                        <h3 className="d-flex">
                                                            Lista alumnos
                                                            <FontAwesomeIcon onClick={this.onClickIconSearch} className={this.state.isSaving ? "not-active iconSearch ms-2" : "iconSearch ms-2"} icon={faSearch} />
                                                            {this.state.filterStudent && <FormControl size="sm" value={this.state.filter} onChange={(event) => this.setState({ filter: event.target.value })} style={{ display: "inline-block", width: "100px", marginLeft: "10px" }} />}
                                                        </h3>
                                                        <Droppable droppableId={"droppable student"}>
                                                            {(droppableProvided) => <ul {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                                                                {students.map((student, index) => {

                                                                    if (this.state.filterStudent) {
                                                                        if ((student.id.toLowerCase()).includes(this.state.filter.toLowerCase()) || (student.name.toLowerCase()).includes(this.state.filter.toLowerCase())) {
                                                                            return (
                                                                                <Draggable key={student.id} draggableId={student.id} index={index}>
                                                                                    {(DraggableProvided) => <li {...DraggableProvided.dragHandleProps} ref={DraggableProvided.innerRef} {...DraggableProvided.draggableProps}>
                                                                                        {this.state.viewId ?
                                                                                            student.id
                                                                                            :
                                                                                            student.name}
                                                                                    </li>}
                                                                                </Draggable>
                                                                            )
                                                                        } else {
                                                                            return null
                                                                        }

                                                                    } else {
                                                                        return (
                                                                            <Draggable key={student.id} draggableId={student.id} index={index}>
                                                                                {(DraggableProvided) => <li {...DraggableProvided.dragHandleProps} ref={DraggableProvided.innerRef} {...DraggableProvided.draggableProps}>
                                                                                    {this.state.viewId ?
                                                                                        student.id
                                                                                        :
                                                                                        student.name}
                                                                                </li>}
                                                                            </Draggable>
                                                                        )
                                                                    }


                                                                })}
                                                                {droppableProvided.placeholder}
                                                            </ul>}
                                                        </Droppable>

                                                    </Col>
                                                </Row>
                                            </DragDropContext>

                                        }
                                    </div>
                                </>
                                :
                                <></>
                            }
                        </>

                    }

                    <Col lg={5} md={6} className='mb-5'>
                        <h3 className="sub-titulo">Nro de avances </h3>
                        {this.state.isLoadingDescription ?
                            <Loading />
                            :
                            <FormControl type="number" value={this.state.avances} onChange={(event) => { let num = parseInt(event.target.value, 10); if (num >= 0) { this.setState({ avances: num }); } }} style={{ width: "150px" }} disabled={this.state.isSaving} />
                        }
                    </Col>

                    {this.state.avances > 0 &&
                        arr.map((dato, index) => (
                            <Col lg={5} md={6} key={"col " + index} className='mb-5' >
                                <h3 key={"h3 " + index} className="sub-titulo">Fecha de entrega avance nro {index + 1}  </h3>
                                <FormControl key={"form " + index} value={dato} type="datetime-local" onChange={this.onChange.bind(this, index)} style={{ width: "200px" }} disabled={this.state.isSaving} />
                            </Col>
                        ))
                    }

                    <Col lg={5} md={6} className='mb-5'>
                        <h3 className="sub-titulo">Nro de entregas </h3>
                        {this.state.isLoadingDescription ?
                            <Loading />
                            :
                            <FormControl type="number" value={this.state.entregas} onChange={(event) => { let num = parseInt(event.target.value, 10); if (num >= 1) { this.setState({ entregas: num }); } }} style={{ width: "150px" }} disabled={this.state.isSaving} />
                        }
                    </Col>

                    {this.state.entregas > 0 &&
                        arrEntregas.map((dato, index) => (
                            <Col lg={5} md={6} key={"col " + index} className='mb-5' >
                                <h3 key={"h3 " + index} className="sub-titulo">Fecha de entrega nro {index + 1}</h3>
                                <FormControl key={"form " + index} value={dato} type="datetime-local" onChange={this.onChangeEntrega.bind(this, index)} style={{ width: "200px" }} disabled={this.state.isSaving} />
                            </Col>
                        ))
                    }

                    <div className='mb-5'>
                        <h3 className="sub-titulo">Detalle  </h3>
                        {this.state.isLoadingDescription ?
                            <Loading />
                            :
                            <>
                                <Editor
                                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                    onInit={(evt, editor) => {
                                        let editorRefDetalle = this.state.editorRefDetalle;
                                        editorRefDetalle.current = editor;
                                        this.setState({ editorRefDetalle: editorRefDetalle })
                                    }}
                                    initialValue={this.state.detalle}
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                    disabled={this.state.isSaving}
                                />

                                <div className='my-3'>
                                    <Row>
                                        <Col>
                                            {this.state.isLoadingDescription ?
                                                <Loading />
                                                :
                                                <div
                                                    className="drop-area"
                                                    id="drop-area-detalle"
                                                    onDragOver={(e) => {
                                                        e.preventDefault()
                                                        let dropArea = document.getElementById("drop-area-detalle")
                                                        dropArea.querySelector("h4").textContent = "Suelta para subir tus archivos"
                                                        dropArea.classList.add("active")
                                                    }}
                                                    onDragLeave={(e) => {
                                                        e.preventDefault()
                                                        let dropArea = document.getElementById("drop-area-detalle")
                                                        dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                        dropArea.classList.remove("active")
                                                    }}
                                                    onDrop={(e) => {
                                                        e.preventDefault()
                                                        this.filesDetalle(e.dataTransfer.files)
                                                        let dropArea = document.getElementById("drop-area-detalle")
                                                        dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                        dropArea.classList.remove("active")
                                                    }}
                                                >
                                                    <h4 id="h4-detalle">Arrastra aquí tus archivos</h4>
                                                    <span>O</span>
                                                    <Button
                                                        className="mt-2"
                                                        variant="secondary"
                                                        disabled={this.state.isSaving}
                                                        onClick={(e) => {
                                                            let input = document.getElementById("inputFileDetalle")
                                                            input.click()
                                                        }}
                                                    >
                                                        Selecciona tus archivos
                                                    </Button>
                                                    <input
                                                        id="inputFileDetalle"
                                                        type="file"
                                                        multiple
                                                        hidden
                                                        value={""}
                                                        onChange={(e) => {
                                                            this.filesDetalle(e.target.files)
                                                        }}
                                                        disabled={this.state.isSaving}
                                                    />
                                                </div>

                                            }
                                        </Col>
                                    </Row>
                                </div>

                                <div className='mb-5'>
                                    <Row>
                                        {filesDetalle.map((data, index) => {
                                            let arr = data.split("/")
                                            let name = arr[arr.length - 1]
                                            arr = name.split(".")
                                            let extension = arr[arr.length - 1]
                                            let posExtension = listExtension.indexOf(extension)
                                            if (posExtension < 0) {
                                                extension = "archivo"
                                            }
                                            return (
                                                <Col key={"col " + index} className="col-file">
                                                    <Row className="card-file">
                                                        <FontAwesomeIcon
                                                            onClick={(e) => {
                                                                this.deleteDetalle(filesDetalle[index], index)
                                                                filesDetalle.splice(index, 1)
                                                                loadingFileDetalle.splice(index, 1)
                                                                this.setState({ filesDetalle: filesDetalle, loadingFileDetalle: loadingFileDetalle })
                                                            }}
                                                            className="col-delete"
                                                            icon={faXmark}
                                                        />
                                                        <Col className="col-img">
                                                            {this.renderImgFileDetalle("./fileIcon/" + extension + ".png", index)}
                                                            {loadingFileDetalle[index] ?
                                                                loadingFileDetalle[index].processing ?
                                                                    <div className="col-carga">
                                                                        <CircularProgressbar
                                                                            value={0}
                                                                            text={`Cargando`}
                                                                            styles={
                                                                                buildStyles({
                                                                                    textColor: "#6c757d",
                                                                                    pathColor: "#6c757d"
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                    :
                                                                    loadingFileDetalle[index].value === loadingFileDetalle[index].total ?
                                                                        <></>
                                                                        :
                                                                        <div className="col-carga">
                                                                            <CircularProgressbar
                                                                                value={loadingFileDetalle[index].value}
                                                                                maxValue={loadingFileDetalle[index].total}
                                                                                text={`${Math.round(loadingFileDetalle[index].value * 100 / loadingFileDetalle[index].total)}%`}
                                                                                styles={
                                                                                    buildStyles({
                                                                                        textColor: "#6c757d",
                                                                                        pathColor: "#6c757d"
                                                                                    })
                                                                                }
                                                                            />
                                                                        </div>
                                                                :
                                                                <></>
                                                            }
                                                        </Col>
                                                        <Col className="col-name">{name}</Col>
                                                    </Row>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </div>

                            </>



                        }

                    </div>

                    <div className='mb-5'>
                        <h3 className="sub-titulo">Estructura de la entrega  </h3>
                        {this.state.isLoadingDescription ?
                            <Loading />
                            :
                            <>

                                <Editor
                                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                    onInit={(evt, editor) => {
                                        let editorRefEstructura = this.state.editorRefEstructura;
                                        editorRefEstructura.current = editor;
                                        this.setState({ editorRefEstructura: editorRefEstructura })
                                    }}
                                    initialValue={this.state.estructura}
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                    disabled={this.state.isSaving}
                                />

                                <div className='my-3'>
                                    <Row>
                                        <Col>
                                            {this.state.isLoadingDescription ?
                                                <Loading />
                                                :
                                                <div
                                                    className="drop-area"
                                                    id="drop-area-estructura"
                                                    onDragOver={(e) => {
                                                        e.preventDefault()
                                                        let dropArea = document.getElementById("drop-area-estructura")
                                                        dropArea.querySelector("h4").textContent = "Suelta para subir tus archivos"
                                                        dropArea.classList.add("active")
                                                    }}
                                                    onDragLeave={(e) => {
                                                        e.preventDefault()
                                                        let dropArea = document.getElementById("drop-area-estructura")
                                                        dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                        dropArea.classList.remove("active")
                                                    }}
                                                    onDrop={(e) => {
                                                        e.preventDefault()
                                                        this.filesEstructura(e.dataTransfer.files)
                                                        let dropArea = document.getElementById("drop-area-estructura")
                                                        dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                        dropArea.classList.remove("active")
                                                    }}
                                                >
                                                    <h4>Arrastra aquí tus archivos</h4>
                                                    <span>O</span>
                                                    <Button
                                                        className="mt-2"
                                                        variant="secondary"
                                                        disabled={this.state.isSaving}
                                                        onClick={(e) => {
                                                            let input = document.getElementById("inputFileEstructura")
                                                            input.click()
                                                        }}
                                                    >
                                                        Selecciona tus archivos
                                                    </Button>
                                                    <input
                                                        id="inputFileEstructura"
                                                        type="file"
                                                        multiple
                                                        hidden
                                                        value={""}
                                                        onChange={(e) => {
                                                            this.filesEstructura(e.target.files)
                                                        }}
                                                        disabled={this.state.isSaving}
                                                    />
                                                </div>

                                            }
                                        </Col>
                                    </Row>
                                </div>

                                <div className='mb-5'>
                                    <Row>
                                        {filesEstructura.map((data, index) => {
                                            let arr = data.split("/")
                                            let name = arr[arr.length - 1]
                                            arr = name.split(".")
                                            let extension = arr[arr.length - 1]
                                            let posExtension = listExtension.indexOf(extension)
                                            if (posExtension < 0) {
                                                extension = "archivo"
                                            }
                                            return (
                                                <Col key={"col " + index} className="col-file">
                                                    <Row className="card-file">
                                                        <FontAwesomeIcon
                                                            onClick={(e) => {
                                                                this.deleteEstructura(filesEstructura[index], index)
                                                                filesEstructura.splice(index, 1)
                                                                loadingFileEstructura.splice(index, 1)
                                                                this.setState({ filesEstructura: filesEstructura, loadingFileEstructura: loadingFileEstructura })
                                                            }}
                                                            className="col-delete"
                                                            icon={faXmark}
                                                        />
                                                        <Col className="col-img">
                                                            {this.renderImgFileEstructura("./fileIcon/" + extension + ".png", index)}
                                                            {loadingFileEstructura[index] ?
                                                                loadingFileEstructura[index].processing ?
                                                                    <div className="col-carga">
                                                                        <CircularProgressbar
                                                                            value={0}
                                                                            text={`Cargando`}
                                                                            styles={
                                                                                buildStyles({
                                                                                    textColor: "#6c757d",
                                                                                    pathColor: "#6c757d"
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                    :
                                                                    loadingFileEstructura[index].value === loadingFileEstructura[index].total ?
                                                                        <></>
                                                                        :
                                                                        <div className="col-carga">
                                                                            <CircularProgressbar
                                                                                value={loadingFileEstructura[index].value}
                                                                                maxValue={loadingFileEstructura[index].total}
                                                                                text={`${Math.round(loadingFileEstructura[index].value * 100 / loadingFileEstructura[index].total)}%`}
                                                                                styles={
                                                                                    buildStyles({
                                                                                        textColor: "#6c757d",
                                                                                        pathColor: "#6c757d"
                                                                                    })
                                                                                }
                                                                            />
                                                                        </div>
                                                                :
                                                                <></>
                                                            }
                                                        </Col>
                                                        <Col className="col-name">{name}</Col>
                                                    </Row>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </div>
                            </>
                        }
                    </div>

                    <div className='mb-5'>
                        <h3 className="sub-titulo">Ejemplos ilustrativos  </h3>
                        {this.state.isLoadingDescription ?
                            <Loading />
                            :
                            <>

                                <Editor
                                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                    onInit={(evt, editor) => {
                                        let editorRefEjemplos = this.state.editorRefEjemplos;
                                        editorRefEjemplos.current = editor;
                                        this.setState({ editorRefEjemplos: editorRefEjemplos })
                                    }}
                                    initialValue={this.state.ejemplos}
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                    disabled={this.state.isSaving}
                                />

                                <div className='my-3'>
                                    <Row>
                                        <Col>
                                            {this.state.isLoadingDescription ?
                                                <Loading />
                                                :
                                                <div
                                                    className="drop-area"
                                                    id="drop-area-ejemplos"
                                                    onDragOver={(e) => {
                                                        e.preventDefault()
                                                        let dropArea = document.getElementById("drop-area-ejemplos")
                                                        dropArea.querySelector("h4").textContent = "Suelta para subir tus archivos"
                                                        dropArea.classList.add("active")
                                                    }}
                                                    onDragLeave={(e) => {
                                                        e.preventDefault()
                                                        let dropArea = document.getElementById("drop-area-ejemplos")
                                                        dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                        dropArea.classList.remove("active")
                                                    }}
                                                    onDrop={(e) => {
                                                        e.preventDefault()
                                                        this.filesEjemplos(e.dataTransfer.files)
                                                        let dropArea = document.getElementById("drop-area-ejemplos")
                                                        dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                        dropArea.classList.remove("active")
                                                    }}
                                                >
                                                    <h4>Arrastra aquí tus archivos</h4>
                                                    <span>O</span>
                                                    <Button
                                                        className="mt-2"
                                                        variant="secondary"
                                                        disabled={this.state.isSaving}
                                                        onClick={(e) => {
                                                            let input = document.getElementById("inputFileEjemplos")
                                                            input.click()
                                                        }}
                                                    >
                                                        Selecciona tus archivos
                                                    </Button>
                                                    <input
                                                        id="inputFileEjemplos"
                                                        type="file"
                                                        multiple
                                                        hidden
                                                        value={""}
                                                        onChange={(e) => {
                                                            this.filesEjemplos(e.target.files)
                                                        }}
                                                        disabled={this.state.isSaving}
                                                    />
                                                </div>

                                            }
                                        </Col>
                                    </Row>
                                </div>

                                <div className='mb-5'>
                                    <Row>
                                        {filesEjemplos.map((data, index) => {
                                            let arr = data.split("/")
                                            let name = arr[arr.length - 1]
                                            arr = name.split(".")
                                            let extension = arr[arr.length - 1]
                                            let posExtension = listExtension.indexOf(extension)
                                            if (posExtension < 0) {
                                                extension = "archivo"
                                            }
                                            return (
                                                <Col key={"col " + index} className="col-file">
                                                    <Row className="card-file">
                                                        <FontAwesomeIcon
                                                            onClick={(e) => {
                                                                this.deleteEjemplos(filesEjemplos[index], index)
                                                                filesEjemplos.splice(index, 1)
                                                                loadingFileEjemplos.splice(index, 1)
                                                                this.setState({ filesEjemplos: filesEjemplos, loadingFileEjemplos: loadingFileEjemplos })
                                                            }}
                                                            className="col-delete"
                                                            icon={faXmark}
                                                        />
                                                        <Col className="col-img">
                                                            {this.renderImgFileEjemplos("./fileIcon/" + extension + ".png", index)}
                                                            {loadingFileEjemplos[index] ?
                                                                loadingFileEjemplos[index].processing ?
                                                                    <div className="col-carga">
                                                                        <CircularProgressbar
                                                                            value={0}
                                                                            text={`Cargando`}
                                                                            styles={
                                                                                buildStyles({
                                                                                    textColor: "#6c757d",
                                                                                    pathColor: "#6c757d"
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                    :
                                                                    loadingFileEjemplos[index].value === loadingFileEjemplos[index].total ?
                                                                        <></>
                                                                        :
                                                                        <div className="col-carga">
                                                                            <CircularProgressbar
                                                                                value={loadingFileEjemplos[index].value}
                                                                                maxValue={loadingFileEjemplos[index].total}
                                                                                text={`${Math.round(loadingFileEjemplos[index].value * 100 / loadingFileEjemplos[index].total)}%`}
                                                                                styles={
                                                                                    buildStyles({
                                                                                        textColor: "#6c757d",
                                                                                        pathColor: "#6c757d"
                                                                                    })
                                                                                }
                                                                            />
                                                                        </div>
                                                                :
                                                                <></>
                                                            }
                                                        </Col>
                                                        <Col className="col-name">{name}</Col>
                                                    </Row>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </div>
                            </>
                        }
                    </div>

                    <div className='mb-5'>
                        <h3 className="sub-titulo">Pauta de evaluación  </h3>
                        {this.state.isLoadingDescription ?
                            <Loading />
                            :
                            <>
                                <Editor
                                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                    onInit={(evt, editor) => {
                                        let editorRefPauta = this.state.editorRefPauta;
                                        editorRefPauta.current = editor;
                                        this.setState({ editorRefPauta: editorRefPauta })
                                    }}
                                    initialValue={this.state.pauta}
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                    disabled={this.state.isSaving}
                                />

                                <div className='my-3'>
                                    <Row>
                                        <Col>
                                            {this.state.isLoadingDescription ?
                                                <Loading />
                                                :
                                                <div
                                                    className="drop-area"
                                                    id="drop-area-pauta"
                                                    onDragOver={(e) => {
                                                        e.preventDefault()
                                                        let dropArea = document.getElementById("drop-area-pauta")
                                                        dropArea.querySelector("h4").textContent = "Suelta para subir tus archivos"
                                                        dropArea.classList.add("active")
                                                    }}
                                                    onDragLeave={(e) => {
                                                        e.preventDefault()
                                                        let dropArea = document.getElementById("drop-area-pauta")
                                                        dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                        dropArea.classList.remove("active")
                                                    }}
                                                    onDrop={(e) => {
                                                        e.preventDefault()
                                                        this.filesPauta(e.dataTransfer.files)
                                                        let dropArea = document.getElementById("drop-area-pauta")
                                                        dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                        dropArea.classList.remove("active")
                                                    }}
                                                >
                                                    <h4>Arrastra aquí tus archivos</h4>
                                                    <span>O</span>
                                                    <Button
                                                        className="mt-2"
                                                        variant="secondary"
                                                        disabled={this.state.isSaving}
                                                        onClick={(e) => {
                                                            let input = document.getElementById("inputFilePauta")
                                                            input.click()
                                                        }}
                                                    >
                                                        Selecciona tus archivos
                                                    </Button>
                                                    <input
                                                        id="inputFilePauta"
                                                        type="file"
                                                        multiple
                                                        hidden
                                                        value={""}
                                                        onChange={(e) => {
                                                            this.filesPauta(e.target.files)
                                                        }}
                                                        disabled={this.state.isSaving}
                                                    />
                                                </div>

                                            }
                                        </Col>
                                    </Row>
                                </div>

                                <div className='mb-5'>
                                    <Row>
                                        {filesPauta.map((data, index) => {
                                            let arr = data.split("/")
                                            let name = arr[arr.length - 1]
                                            arr = name.split(".")
                                            let extension = arr[arr.length - 1]
                                            let posExtension = listExtension.indexOf(extension)
                                            if (posExtension < 0) {
                                                extension = "archivo"
                                            }
                                            return (
                                                <Col key={"col " + index} className="col-file">
                                                    <Row className="card-file">
                                                        <FontAwesomeIcon
                                                            onClick={(e) => {
                                                                this.deletePauta(filesPauta[index], index)
                                                                filesPauta.splice(index, 1)
                                                                loadingFilePauta.splice(index, 1)
                                                                this.setState({ filesPauta: filesPauta, loadingFilePauta: loadingFilePauta })
                                                            }}
                                                            className="col-delete"
                                                            icon={faXmark}
                                                        />
                                                        <Col className="col-img">
                                                            {this.renderImgFilePauta("./fileIcon/" + extension + ".png", index)}
                                                            {loadingFilePauta[index] ?
                                                                loadingFilePauta[index].processing ?
                                                                    <div className="col-carga">
                                                                        <CircularProgressbar
                                                                            value={0}
                                                                            text={`Cargando`}
                                                                            styles={
                                                                                buildStyles({
                                                                                    textColor: "#6c757d",
                                                                                    pathColor: "#6c757d"
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                    :
                                                                    loadingFilePauta[index].value === loadingFilePauta[index].total ?
                                                                        <></>
                                                                        :
                                                                        <div className="col-carga">
                                                                            <CircularProgressbar
                                                                                value={loadingFilePauta[index].value}
                                                                                maxValue={loadingFilePauta[index].total}
                                                                                text={`${Math.round(loadingFilePauta[index].value * 100 / loadingFilePauta[index].total)}%`}
                                                                                styles={
                                                                                    buildStyles({
                                                                                        textColor: "#6c757d",
                                                                                        pathColor: "#6c757d"
                                                                                    })
                                                                                }
                                                                            />
                                                                        </div>
                                                                :
                                                                <></>
                                                            }
                                                        </Col>
                                                        <Col className="col-name">{name}</Col>
                                                    </Row>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </div>
                            </>
                        }
                    </div>

                    <div className='mb-5'>
                        <h3 className="sub-titulo">Contenidos del curso  </h3>
                        {this.state.isLoadingDescription ?
                            <Loading />
                            :
                            <>
                                <Editor
                                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                    onInit={(evt, editor) => {
                                        let editorRefContenidos = this.state.editorRefContenidos;
                                        editorRefContenidos.current = editor;
                                        this.setState({ editorRefContenidos: editorRefContenidos })
                                    }}
                                    initialValue={this.state.contenidos}
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                    disabled={this.state.isSaving}
                                />

                                <div className='my-3'>
                                    <Row>
                                        <Col>
                                            {this.state.isLoadingDescription ?
                                                <Loading />
                                                :
                                                <div
                                                    className="drop-area"
                                                    id="drop-area-contenidos"
                                                    onDragOver={(e) => {
                                                        e.preventDefault()
                                                        let dropArea = document.getElementById("drop-area-contenidos")
                                                        dropArea.querySelector("h4").textContent = "Suelta para subir tus archivos"
                                                        dropArea.classList.add("active")
                                                    }}
                                                    onDragLeave={(e) => {
                                                        e.preventDefault()
                                                        let dropArea = document.getElementById("drop-area-contenidos")
                                                        dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                        dropArea.classList.remove("active")
                                                    }}
                                                    onDrop={(e) => {
                                                        e.preventDefault()
                                                        this.filesContenidos(e.dataTransfer.files)
                                                        let dropArea = document.getElementById("drop-area-contenidos")
                                                        dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                        dropArea.classList.remove("active")
                                                    }}
                                                >
                                                    <h4>Arrastra aquí tus archivos</h4>
                                                    <span>O</span>
                                                    <Button
                                                        className="mt-2"
                                                        variant="secondary"
                                                        disabled={this.state.isSaving}
                                                        onClick={(e) => {
                                                            let input = document.getElementById("inputFileContenidos")
                                                            input.click()
                                                        }}
                                                    >
                                                        Selecciona tus archivos
                                                    </Button>
                                                    <input
                                                        id="inputFileContenidos"
                                                        type="file"
                                                        multiple
                                                        hidden
                                                        value={""}
                                                        onChange={(e) => {
                                                            this.filesContenidos(e.target.files)
                                                        }}
                                                        disabled={this.state.isSaving}
                                                    />
                                                </div>

                                            }
                                        </Col>
                                    </Row>
                                </div>

                                <div className='mb-5'>
                                    <Row>
                                        {filesContenidos.map((data, index) => {
                                            let arr = data.split("/")
                                            let name = arr[arr.length - 1]
                                            arr = name.split(".")
                                            let extension = arr[arr.length - 1]
                                            let posExtension = listExtension.indexOf(extension)
                                            if (posExtension < 0) {
                                                extension = "archivo"
                                            }
                                            return (
                                                <Col key={"col " + index} className="col-file">
                                                    <Row className="card-file">
                                                        <FontAwesomeIcon
                                                            onClick={(e) => {
                                                                this.deleteContenidos(filesContenidos[index], index)
                                                                filesContenidos.splice(index, 1)
                                                                loadingFileContenidos.splice(index, 1)
                                                                this.setState({ filesContenidos: filesContenidos, loadingFileContenidos: loadingFileContenidos })
                                                            }}
                                                            className="col-delete"
                                                            icon={faXmark}
                                                        />
                                                        <Col className="col-img">
                                                            {this.renderImgFileContenidos("./fileIcon/" + extension + ".png", index)}
                                                            {loadingFileContenidos[index] ?
                                                                loadingFileContenidos[index].processing ?
                                                                    <div className="col-carga">
                                                                        <CircularProgressbar
                                                                            value={0}
                                                                            text={`Cargando`}
                                                                            styles={
                                                                                buildStyles({
                                                                                    textColor: "#6c757d",
                                                                                    pathColor: "#6c757d"
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                    :
                                                                    loadingFileContenidos[index].value === loadingFileContenidos[index].total ?
                                                                        <></>
                                                                        :
                                                                        <div className="col-carga">
                                                                            <CircularProgressbar
                                                                                value={loadingFileContenidos[index].value}
                                                                                maxValue={loadingFileContenidos[index].total}
                                                                                text={`${Math.round(loadingFileContenidos[index].value * 100 / loadingFileContenidos[index].total)}%`}
                                                                                styles={
                                                                                    buildStyles({
                                                                                        textColor: "#6c757d",
                                                                                        pathColor: "#6c757d"
                                                                                    })
                                                                                }
                                                                            />
                                                                        </div>
                                                                :
                                                                <></>
                                                            }
                                                        </Col>
                                                        <Col className="col-name">{name}</Col>
                                                    </Row>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </div>
                            </>
                        }
                    </div>

                    <div className='mb-5'>
                        <Row className="justify-content-end">
                            <Col xs={3} sm={2} lg={2} xl={2}><Button variant="secondary" onClick={this.save} disabled={this.state.isSaving} >Guardar</Button></Col>
                            <Col xs={3} sm={2} lg={2} xl={2}><Link className={this.state.isSaving ? "not-active btn btn-danger " : "btn btn-danger "} to="/" disabled={this.state.isSaving} >Cancelar</Link></Col>
                        </Row>
                    </div>
                </Row>
            </div>
        );
    }
}

export default withParams(CreateProject);