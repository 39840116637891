import React, { Component } from "react"
import { useNavigate, Link } from "react-router-dom"
import { Api } from "../../AWS/aws"
import { Button, FormControl, Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Editor } from '@tinymce/tinymce-react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Upload } from '@aws-sdk/lib-storage';
import { S3Client, DeleteObjectCommand } from '@aws-sdk/client-s3';
import Loading from "../../Components/Loading"
import listExtension from "../../Assets/Js/listExtension"
import Swal from 'sweetalert2'
import "./CreateCourse.css"

function withParams(Component) {
    return props => <Component {...props} navigate={useNavigate()} />;
}

class CreateCourse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: Date.now(),
            id: false,
            isSaving: false,
            isLoading: false,
            isLoadingStudents: true,
            viewId: false,
            filterStudent: false,
            filter: '',
            students: [],
            nombre: '',
            studentCourse: [],
            editorRefDescripcion: React.createRef(null),
            descripcion: "",
            filesDescripcion: [],
            loadingFileDescripcion: [],
            parallelUploads3Descripcion: [],

        };
    }

    getStudents() {
        return Api({
            path: `/teacher/students`
        })
    }

    postCourse(body) {
        return Api({
            path: `/teacher/course`,
            body: body,
            method: 'POST'
        })
    }


    componentDidMount() {
        this.getStudents().then(res => {
            if (res.code === 0) {
                this.setState({ isLoadingStudents: false, students: res.body })
            } else {
                this.setState({ isLoadingStudents: false })
            }
        })
    }

    save = () => {

        if (!this.state.nombre) {
            Swal.fire({
                icon: 'error',
                text: 'No puede dejar el nombre en blanco!',
                confirmButtonColor:"#dc3545"
            })
            return
        }

        this.setState({ isSaving: true })

        let descripcion = this.state.editorRefDescripcion.current.getContent()

        let body = {
            id: this.state.id,
            nombre: this.state.nombre,
            studentCourse: this.state.studentCourse,
            descripcion: descripcion,
            files: this.state.filesDescripcion
        }

        this.postCourse(body).then((res) => {

            Swal.fire({
                icon: 'success',
                text: 'Curso '+this.state.nombre+' creado exitosamente!',
                confirmButtonColor:"#ffc107"
            })

            this.setState({ isSaving: false })
            this.props.navigate("/")
        })

    }

    onDragEnd = (result) => {
        if (!this.state.isSaving) {

            const { destination, source } = result
            if (!destination) {
                return
            }
            if (destination.index === source.index && destination.droppableId === source.droppableId) {
                return
            }
            let course = this.state.studentCourse
            let students = this.state.students

            if (destination.droppableId === "course" && source.droppableId === "student") {

                let aux = students[source.index]
                students.splice(source.index, 1)
                course.splice(destination.index, 0, aux)

            } if (destination.droppableId === "student" && source.droppableId === "course") {

                let aux = course[source.index]
                course.splice(source.index, 1)
                students.splice(destination.index, 0, aux)

            } if (destination.droppableId === "student" && destination.droppableId === source.droppableId) {

                let aux = students[source.index]
                students[source.index] = students[destination.index]
                students[destination.index] = aux

            } if (destination.droppableId === "course" && destination.droppableId === source.droppableId) {

                let aux = course[source.index]
                course[source.index] = course[destination.index]
                course[destination.index] = aux
            }

            this.setState({ studentCourse: course, students: students })
        }
    }

    onClickIconSearch = () => {
        this.setState({ filterStudent: !this.state.filterStudent, filter: '' })
    }

    uploadS3 = async (file, key, fun) => {

        const target = {
            Bucket: "tallervirtualarchivos",
            Key: key,
            Body: file,
            ACL: "public-read",
            ContentType: file.type
        }
        const creds = {
            accessKeyId: "AKIA2T5OXYWGGXOKREU2",
            secretAccessKey: "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",
        }
        try {
            const parallelUploads3 = new Upload({
                client: new S3Client({
                    region: "us-east-1",
                    credentials: creds
                }),
                partSize: 1024 * 1024 * 20,
                params: target,
            })

            let arr = this.state.parallelUploads3Descripcion
            let pos = this.state.filesDescripcion.indexOf(key)
            if (pos !== -1) {
                arr[pos] = parallelUploads3
                this.setState({ parallelUploads3Descripcion: arr })
            }

            parallelUploads3.on("httpUploadProgress", (process) => {
                fun(process)
            })

            await parallelUploads3.done()
            return true
        } catch (e) {
            console.log(e);
            return false
        }
    }

    deleteS3 = (key) => {

        const target = {
            Bucket: "tallervirtualarchivos",
            Key: key
        }

        const creds = {
            accessKeyId: "AKIA2T5OXYWGGXOKREU2",
            secretAccessKey: "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",
        }

        const client = new S3Client({
            region: "us-east-1",
            credentials: creds
        })

        const command = new DeleteObjectCommand(target)

        client.send(command).then((res) => {
            console.log(res);
        })
    }

    renderImgFileDetalle(ruta, index) {

        if (this.state.loadingFileDescripcion[index]) {
            if (this.state.loadingFileDescripcion[index].processing) {
                return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
            } else {
                if (this.state.loadingFileDescripcion[index].value === this.state.loadingFileDescripcion[index].total) {
                    return (<img width={50} src={ruta} alt="archivo" />)
                } else {
                    return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
                }
            }
        } else {
            return (<img width={50} src={ruta} alt="archivo" />)
        }

    }

    filesDescripcion = (eventFiles) => {
        let date = this.state.date
        let files = this.state.filesDescripcion
        let teacher = this.props.user

        for (let index = 0; index < eventFiles.length; index++) {
            let key = teacher.id + "/" + date + "/description/" + eventFiles[index].name
            if (files.indexOf(key) < 0) {
                files.push(key)
                let loadingFileDescripcion = this.state.loadingFileDescripcion

                loadingFileDescripcion[files.indexOf(key)] = { processing: true }
                this.setState({ loadingFileDescripcion: loadingFileDescripcion })

                this.uploadS3(eventFiles[index], key, (data) => {
                    let i = files.indexOf(key)
                    let loadingFileDescripcion = this.state.loadingFileDescripcion
                    loadingFileDescripcion[i] = { value: data.loaded, total: data.total }
                    this.setState({ loadingFileDescripcion: loadingFileDescripcion })
                }).then((res) => {
                    console.log(res);
                })
            }
        }

        this.setState({ filesDescripcion: files })
    }

    deleteDescripcion = (key, i) => {

        if (this.state.loadingFileDescripcion[i]) {
            if (this.state.loadingFileDescripcion[i].processing) {
                //se esta cargando archivo
                let arr = this.state.parallelUploads3Descripcion
                arr[i].abort()
                arr.splice(i, 1)
                this.setState({ parallelUploads3Descripcion: arr })
            } else {
                if (this.state.loadingFileDescripcion[i].value === this.state.loadingFileDescripcion[i].total) {
                    //ya se cargo el archivo
                    this.deleteS3(key)
                } else {
                    //se esta cargando archivo
                    let arr = this.state.parallelUploads3Descripcion
                    arr[i].abort()
                    arr.splice(i, 1)
                    this.setState({ parallelUploads3Descripcion: arr })
                }
            }
        } else {

        }
    }

    render() {

        let filesDescripcion = this.state.filesDescripcion

        let loadingFileDescripcion = this.state.loadingFileDescripcion

        let students = this.state.students

        return (
            <div className="CreateCourse">
                <h1 className="titulo animate__animated animate__fadeInDown">Creación de asignatura</h1>
                <Row className="contenedor animate__animated animate__fadeInLeft">


                    <div className=''>
                        <Row className="justify-content-end">
                            <Col xs={3} sm={2} lg={2} xl={2}><Button variant="secondary" onClick={this.save} disabled={this.state.isSaving}>Guardar</Button></Col>
                            <Col xs={3} sm={2} lg={2} xl={2}><Link className={this.state.isSaving ? "not-active btn btn-danger " : "btn btn-danger "} to="/">Cancelar</Link></Col>
                        </Row>
                    </div>

                    <div className='mb-4'>
                        <h3>Nombre</h3>
                        {this.state.isLoading ?
                            <Loading />
                            :
                            <FormControl value={this.state.nombre} onChange={(event) => this.setState({ nombre: event.target.value })} style={{ width: "330px" }} disabled={this.state.isSaving} />
                        }
                    </div>

                    <div className='mb-4'>
                        <h3>Descripcion</h3>
                        {this.state.isLoading ?
                            <Loading />
                            :
                            <>
                                <Editor
                                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                    onInit={(evt, editor) => {
                                        let editorRefDescripcion = this.state.editorRefDescripcion;
                                        editorRefDescripcion.current = editor;
                                        this.setState({ editorRefDescripcion: editorRefDescripcion })
                                    }}
                                    initialValue={this.state.descripcion}
                                    init={{
                                        height: 400,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}
                                    disabled={this.state.isSaving}
                                />
                                <div className='my-3'>
                                    <Row>
                                        <Col>
                                            {this.state.isLoading ?
                                                <Loading />
                                                :
                                                <div
                                                    className="drop-area"
                                                    id="drop-area-detalle"
                                                    onDragOver={(e) => {
                                                        e.preventDefault()
                                                        let dropArea = document.getElementById("drop-area-detalle")
                                                        dropArea.querySelector("h4").textContent = "Suelta para subir tus archivos"
                                                        dropArea.classList.add("active")
                                                    }}
                                                    onDragLeave={(e) => {
                                                        e.preventDefault()
                                                        let dropArea = document.getElementById("drop-area-detalle")
                                                        dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                        dropArea.classList.remove("active")
                                                    }}
                                                    onDrop={(e) => {
                                                        e.preventDefault()
                                                        this.filesDescripcion(e.dataTransfer.files)
                                                        let dropArea = document.getElementById("drop-area-detalle")
                                                        dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                        dropArea.classList.remove("active")
                                                    }}
                                                >
                                                    <h4 id="h4-detalle">Arrastra aquí tus archivos</h4>
                                                    <span>O</span>
                                                    <Button
                                                        className="mt-2"
                                                        variant="secondary"
                                                        disabled={this.state.isSaving}
                                                        onClick={(e) => {
                                                            let input = document.getElementById("inputFileDetalle")
                                                            input.click()
                                                        }}
                                                    >
                                                        Selecciona tus archivos
                                                    </Button>
                                                    <input
                                                        id="inputFileDetalle"
                                                        type="file"
                                                        multiple
                                                        hidden
                                                        value={""}
                                                        onChange={(e) => {
                                                            this.filesDescripcion(e.target.files)
                                                        }}
                                                        disabled={this.state.isSaving}
                                                    />
                                                </div>

                                            }
                                        </Col>
                                    </Row>
                                </div>

                                <div className='mb-5'>
                                    <Row>
                                        {filesDescripcion.map((data, index) => {
                                            let arr = data.split("/")
                                            let name = arr[arr.length - 1]
                                            arr = name.split(".")
                                            let extension = arr[arr.length - 1]
                                            let posExtension = listExtension.indexOf(extension)
                                            if (posExtension < 0) {
                                                extension = "archivo"
                                            }
                                            return (
                                                <Col key={"col " + index} className="col-file">
                                                    <Row className="card-file">
                                                        <FontAwesomeIcon
                                                            onClick={(e) => {
                                                                this.deleteDescripcion(filesDescripcion[index], index)
                                                                filesDescripcion.splice(index, 1)
                                                                loadingFileDescripcion.splice(index, 1)
                                                                this.setState({ filesDescripcion: filesDescripcion, loadingFileDescripcion: loadingFileDescripcion })
                                                            }}
                                                            className="col-delete"
                                                            icon={faXmark}
                                                        />
                                                        <Col className="col-img">
                                                            {this.renderImgFileDetalle("./fileIcon/" + extension + ".png", index)}
                                                            {loadingFileDescripcion[index] ?
                                                                loadingFileDescripcion[index].processing ?
                                                                    <div className="col-carga">
                                                                        <CircularProgressbar
                                                                            value={0}
                                                                            text={`Cargando`}
                                                                            styles={
                                                                                buildStyles({
                                                                                    textColor: "#6c757d",
                                                                                    pathColor: "#6c757d"
                                                                                })
                                                                            }
                                                                        />
                                                                    </div>
                                                                    :
                                                                    loadingFileDescripcion[index].value === loadingFileDescripcion[index].total ?
                                                                        <></>
                                                                        :
                                                                        <div className="col-carga">
                                                                            <CircularProgressbar
                                                                                value={loadingFileDescripcion[index].value}
                                                                                maxValue={loadingFileDescripcion[index].total}
                                                                                text={`${Math.round(loadingFileDescripcion[index].value * 100 / loadingFileDescripcion[index].total)}%`}
                                                                                styles={
                                                                                    buildStyles({
                                                                                        textColor: "#6c757d",
                                                                                        pathColor: "#6c757d"
                                                                                    })
                                                                                }
                                                                            />
                                                                        </div>
                                                                :
                                                                <></>
                                                            }
                                                        </Col>
                                                        <Col className="col-name">{name}</Col>
                                                    </Row>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </div>
                            </>
                        }
                    </div>

                    <div className='mb-4'>
                        <h3>Alumnos</h3>
                        {this.state.isLoadingStudents ?
                            <Loading />
                            :
                            <>
                                <Form.Check
                                    type="radio"
                                    className="ms-3"
                                    id={`default-rut-radio`}
                                    label={`Ver alumnos por Rut`}
                                    checked={this.state.viewId}
                                    onChange={(e) => { this.setState({ viewId: true }) }}
                                    disabled={this.state.isSaving}
                                />
                                <Form.Check
                                    type="radio"
                                    className="mb-2 ms-3"
                                    id={`default-rut-radio`}
                                    label={`Ver alumnos por Nombre`}
                                    checked={!this.state.viewId}
                                    onChange={(e) => { this.setState({ viewId: false }) }}
                                    disabled={this.state.isSaving}
                                />

                            </>
                        }
                    </div>

                    <div className='mb-4'>
                        {this.state.isLoadingStudents ?
                            <></>
                            :
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Row className="row-course-and-list justify-content-center">
                                    <Col xs={10} sm={5} className="col-course">
                                        <h4>Asignatura</h4>
                                        <Droppable droppableId={"course"}>
                                            {(droppableProvided) => <ul {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                                                {this.state.studentCourse.map((student, index) => (
                                                    <Draggable key={student.id} draggableId={student.id} index={index}>
                                                        {(DraggableProvided) =>
                                                            <li className="li" {...DraggableProvided.dragHandleProps} ref={DraggableProvided.innerRef} {...DraggableProvided.draggableProps}>
                                                                {this.state.viewId ?
                                                                    student.id
                                                                    :
                                                                    student.name}
                                                            </li>}
                                                    </Draggable>
                                                ))}
                                                {droppableProvided.placeholder}
                                            </ul>}
                                        </Droppable>
                                    </Col>
                                    <Col xs={10} sm={5} className="col-list">
                                        <h4>
                                            Lista alumnos
                                            <FontAwesomeIcon onClick={this.onClickIconSearch} className={this.state.isSaving ? "not-active iconSearch ms-2" : "iconSearch ms-2"} icon={faSearch} />
                                            {this.state.filterStudent && <FormControl size="sm" value={this.state.filter} onChange={(event) => this.setState({ filter: event.target.value })} style={{ display: "inline-block", width: "100px" }} />}
                                        </h4>
                                        <Droppable droppableId={"student"}>
                                            {(droppableProvided) => <ul {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                                                {students.map((student, index) => {

                                                    if (this.state.filterStudent) {
                                                        if ((student.id.toLowerCase()).includes(this.state.filter.toLowerCase()) || (student.name.toLowerCase()).includes(this.state.filter.toLowerCase())) {
                                                            return (
                                                                <Draggable key={student.id} draggableId={student.id} index={index}>
                                                                    {(DraggableProvided) => <li className="li" {...DraggableProvided.dragHandleProps} ref={DraggableProvided.innerRef} {...DraggableProvided.draggableProps}>
                                                                        {this.state.viewId ?
                                                                            student.id
                                                                            :
                                                                            student.name}
                                                                    </li>}
                                                                </Draggable>

                                                            )
                                                        } else {
                                                            return null
                                                        }

                                                    } else {
                                                        return (
                                                            <Draggable key={student.id} draggableId={student.id} index={index}>
                                                                {(DraggableProvided) => <li className="li" {...DraggableProvided.dragHandleProps} ref={DraggableProvided.innerRef} {...DraggableProvided.draggableProps}>
                                                                    {this.state.viewId ?
                                                                        student.id
                                                                        :
                                                                        student.name}
                                                                </li>}
                                                            </Draggable>

                                                        )
                                                    }
                                                })}
                                                {droppableProvided.placeholder}
                                            </ul>}
                                        </Droppable>
                                    </Col>
                                </Row>
                            </DragDropContext>
                        }
                    </div>

                    <div className=''>
                        <Row className="justify-content-end">
                            <Col xs={3} sm={2} lg={2} xl={2}><Button variant="secondary" onClick={this.save} disabled={this.state.isSaving}>Guardar</Button></Col>
                            <Col xs={3} sm={2} lg={2} xl={2}><Link className={this.state.isSaving ? "not-active btn btn-danger " : "btn btn-danger "} to="/">Cancelar</Link></Col>
                        </Row>
                    </div>


                </Row>
            </div>
        )
    }
}

export default withParams(CreateCourse);