import React, { Component } from "react"
import { Row, Col, Card, Modal, Button, DropdownButton, Dropdown, FormControl } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Draggable from 'react-draggable';
import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faPenToSquare, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faUser } from "@fortawesome/free-regular-svg-icons";
import Loading from "../../Components/Loading"
import AddFav from "../../Components/AddFav"
import { Api } from "../../AWS/aws"
import listExtension from "../../Assets/Js/listExtension"
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2'
import "./MoodboardTeacher.css"

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class MoodboardTeacher extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: Date.now(),
            textH4: "",
            isLoading: true,
            isLoadingMoodboars: true,
            isSaving: false,
            isDelete: false,
            show: false,
            newCardType: "Texto",
            newCardFile: "",
            isLoadingFile: null,
            loadingFile: null,
            parallelUploads3: null,
            moodboarCard: [],
            newCardText: "",
            newCardComentario: "",
            id: null,
            newCardSizeTitle: "Pequeño",
            newCardSizeX: "150px",
            newCardSizeY: "100px",
            edit: true,
            otherMoodboars: [],
            favMoodboars: [],
            nameMoodboard: false,
            idMoodboard: null,
            isFav: false,
            username: false
        };
    }

    getStudentMoodboard(id) {
        return Api({
            path: `/student/moodboard/${id}`
        })
    }

    getOtherStudentMoodboard(id) {
        return Api({
            path: `/student/moodboard/${this.props.params.id}/${id}`
        })
    }

    getProjectMoodboard(id) {
        return Api({
            path: `/project/moodboard/${id}`
        })
    }

    postStudentMoodboardCard(body) {
        return Api({
            path: `/student/moodboard/card`,
            method: "POST",
            body: body
        })
    }

    postStudentMoodboardCardPos(body) {
        return Api({
            path: `/student/moodboard/card/pos`,
            method: "POST",
            body: body
        })
    }

    deleteStudentMoodboardCard(id) {
        return Api({
            path: `/student/moodboard/card/${id}`,
            method: "DELETE"
        })
    }

    componentDidMount() {

        this.getProjectMoodboard(this.props.params.id).then((res) => {
            if (res.code === 0) {
                this.setState({ favMoodboars: res.body.favMoodboars, otherMoodboars: res.body.otherMoodboars, isLoadingMoodboars: false })
            }
        })
    }

    findPosNewCard = () => {
        let moodboarCard = this.state.moodboarCard

        let sizeX = parseInt(this.state.newCardSizeX.replace("px", ""))
        let sizeY = parseInt(this.state.newCardSizeY.replace("px", ""))

        let maxX = 1371 - sizeX
        let maxY = 1000 - sizeY

        for (let i = 0; i < maxY; i++) {
            for (let j = 0; j < maxX; j++) {
                let arr = moodboarCard.filter(res => {
                    let sizeXres = parseInt(res.sizeX.replace("px", ""))
                    let sizeYres = parseInt(res.sizeY.replace("px", ""))

                    let inicioY = i
                    let inicioX = j
                    let finY = i + sizeY
                    let finX = j + sizeX

                    let inicioYdentro = (res.y >= inicioY && res.y <= finY)
                    let inicioXdentro = (res.x >= inicioX && res.x <= finX)

                    let finYdentro = ((res.y + sizeYres) >= inicioY && (res.y + sizeYres) <= finY)
                    let finXdentro = ((res.x + sizeXres) >= inicioX && (res.x + sizeXres) <= finX)

                    let bol = (inicioYdentro && inicioXdentro) || (finYdentro && finXdentro) || ((inicioY >= res.y && inicioY <= (res.y + sizeYres)) && (inicioX >= res.x && inicioX <= (res.x + sizeXres))) || ((finY >= res.y && finY <= (res.y + sizeYres)) && (finX >= res.x && finX <= (res.x + sizeXres)))

                    return bol
                })
                if (arr.length === 0) {
                    let pos = {
                        y: i,
                        x: j
                    }
                    return pos
                }
            }
        }

        return {
            y: 0,
            x: 0
        }

    }

    saveCard = () => {
        this.setState({ isSaving: true })

        let body = {}

        if (this.state.username) {
            if (this.state.id) {
                body = {
                    id: this.state.id,
                    type: this.state.newCardType,
                    file: this.state.newCardFile,
                    idProject: this.props.params.id,
                    text: this.state.newCardText,
                    comment: this.state.newCardComentario,
                    sizeX: this.state.newCardSizeX,
                    sizeY: this.state.newCardSizeY,
                    username: this.state.username
                }
            } else {

                let pos = this.findPosNewCard()

                body = {
                    type: this.state.newCardType,
                    file: this.state.newCardFile,
                    idProject: this.props.params.id,
                    text: this.state.newCardText,
                    comment: this.state.newCardComentario,
                    sizeX: this.state.newCardSizeX,
                    sizeY: this.state.newCardSizeY,
                    username: this.state.username,
                    x: pos.x,
                    y: pos.y
                }
            }

            this.postStudentMoodboardCard(body).then((res) => {
                let moodboarCard = this.state.moodboarCard
                if (res.type) {
                    Swal.fire({
                        icon: 'success',
                        text: 'Tarjeta actualizada exitosamente!',
                        confirmButtonColor:"#ffc107"
                    })
                    let arr = moodboarCard.filter(card => card.id === this.state.id)
                    let pos = moodboarCard.indexOf(arr[0])
                    moodboarCard[pos].comment = res.body.comment
                    moodboarCard[pos].file = res.body.file
                    moodboarCard[pos].text = res.body.text
                    moodboarCard[pos].type = res.body.type
                    moodboarCard[pos].sizeX = res.body.sizeX
                    moodboarCard[pos].sizeY = res.body.sizeY
                    if (res.body.by) {
                        moodboarCard[pos].by = res.body.by
                    } else {
                        delete moodboarCard[pos].by
                    }
                } else {
                    Swal.fire({
                        icon: 'success',
                        text: 'Tarjeta creada exitosamente!',
                        confirmButtonColor:"#ffc107"
                    })
                    moodboarCard.push(res.body)
                }

                this.setState({ isSaving: false, show: false, newCardFile: "", newCardSizeTitle: "Pequeño", newCardSizeX: "150px", newCardSizeY: "100px", isLoadingFile: null, loadingFile: null, parallelUploads3: null, newCardText: "", newCardType: "Texto", newCardComentario: "", id: null, moodboarCard: moodboarCard })
            })
        }


    }

    onStop = (index, e, node) => {
        let arr = this.state.moodboarCard

        arr[index]["x"] = arr[index]["x"] + node.lastX
        arr[index]["y"] = arr[index]["y"] + node.lastY

        this.postStudentMoodboardCardPos({
            id: arr[index]["id"],
            x: arr[index]["x"],
            y: arr[index]["y"]
        }).then((res) => {
        })

        this.setState({ moodboarCard: arr })
    }

    uploadS3 = async (file, key, fun) => {

        const target = {
            Bucket: "tallervirtualarchivos",
            Key: key,
            Body: file,
            ACL: "public-read",
            ContentType: file.type
        }
        const creds = {
            accessKeyId: "AKIA2T5OXYWGGXOKREU2",
            secretAccessKey: "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",
        }
        try {
            const parallelUploads3 = new Upload({
                client: new S3Client({
                    region: "us-east-1",
                    credentials: creds
                }),
                partSize: 1024 * 1024 * 20,
                params: target,
            })
            this.setState({ parallelUploads3: parallelUploads3 })

            parallelUploads3.on("httpUploadProgress", (process) => {
                fun(process)
            })

            await parallelUploads3.done()
            return true
        } catch (e) {
            console.log(e);
            return false
        }
    }

    onEdit = (data, e) => {
        let title = "Pequeño"
        if (data.sizeX === "270px" && data.sizeY === "170px") {
            title = "Mediano"
        }

        if (data.sizeX === "380px" && data.sizeY === "230px") {
            title = "Grande"
        }
        let textH4 = "Arrastra aquí una imagen"

        if (data.type === "Video") {
            textH4 = "Arrastra aquí un video"
        }

        if (data.type === "Archivo") {
            textH4 = "Arrastra aquí un archivo"
        }

        this.setState({ show: true, id: data.id, textH4: textH4, newCardType: data.type, newCardText: data.text, newCardFile: data.file, newCardComentario: data.comment, newCardSizeTitle: title, newCardSizeX: data.sizeX, newCardSizeY: data.sizeY })
    }

    newMoodboard = (data, fav) => {
        this.getOtherStudentMoodboard(data.id).then((res) => {
            if (res.code === 0) {
                this.setState({ moodboarCard: res.body, nameMoodboard: data.student.name, idMoodboard: data.id, isFav: fav, username: data.idStudent })
            }
            let project_outlet = document.getElementsByClassName("project-outlet")
            project_outlet[0].scrollTop = 0
        })
    }

    deleteCard = () => {
        this.setState({ isDelete: true })
        if (this.state.id) {
            Swal.fire({
                title: '¿Esta seguro/a que desea eliminar la tarjeta?',
                showCancelButton: true,
                confirmButtonText: 'Eliminar',
                confirmButtonColor: "#dc3545",
                cancelButtonText: "Cancelar"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteStudentMoodboardCard(this.state.id).then(res => {
                        let moodboarCard = this.state.moodboarCard
                        moodboarCard = moodboarCard.filter(card => card.id !== this.state.id)
                        Swal.fire({
                            icon: 'success',
                            text: 'Tarjeta eliminada exitosamente!',
                            confirmButtonColor:"#ffc107"
                        })
                        this.setState({ isDelete: false, show: false, newCardFile: "", newCardSizeTitle: "Pequeño", newCardSizeX: "150px", newCardSizeY: "100px", isLoadingFile: null, loadingFile: null, parallelUploads3: null, newCardText: "", newCardType: "Texto", newCardComentario: "", id: null, moodboarCard: moodboarCard })
                    })
                } else {
                    this.setState({ isDelete: false })
                }
            })
            
        }
    }

    returnMyMoodboard = () => {
        this.setState({ moodboarCard: [], idMoodboard: null, isFav: false, nameMoodboard: false })
    }

    render() {
        let newCardFile = this.state.newCardFile
        let extension = ""
        let name = ""
        if (newCardFile !== "") {
            let arr = newCardFile.split("/")
            name = arr[arr.length - 1]
            arr = name.split(".")
            extension = arr[arr.length - 1]
            let posExtension = listExtension.indexOf(extension)
            if (posExtension < 0) {
                extension = "archivo"
            }
        }
        return (
            <div className="MoodboardTeacher">

                {
                    this.state.nameMoodboard ?
                        <div className="container-info">
                            <h2 className="title mb-2">{this.state.nameMoodboard} <AddFav id={this.state.idMoodboard} idProject={this.props.params.id} fav={this.state.isFav} /><Button variant="secondary" onClick={() => { this.setState({ show: true }) }}>Nueva tarjeta</Button> <Button variant="secondary" onClick={this.returnMyMoodboard}>Cerrar</Button></h2>
                            <div style={{ maxWidth: "1380px", height: "590px", overflowY: "auto", position: 'relative' }}>
                                <div id="card-content" className="card-content">
                                    {this.state.moodboarCard.map((data, index) => {
                                        let file = data.file
                                        let extension = ""
                                        let name = ""
                                        if (file !== "") {
                                            let arr = file.split("/")
                                            name = arr[arr.length - 1]
                                            arr = name.split(".")
                                            extension = arr[arr.length - 1]
                                            let posExtension = listExtension.indexOf(extension)
                                            if (posExtension < 0) {
                                                extension = "archivo"
                                            }
                                        }

                                        return (
                                            <Draggable
                                                onStop={this.onStop.bind(this, index)}
                                                bounds="parent"
                                                cancel="img"
                                                key={"draggable " + index}
                                                position={{ x: 0, y: 0 }}
                                                disabled={!this.state.edit}
                                            >
                                                {data.type === "Imagen" ?
                                                    <Card style={data.by==="TEACHER" ? { width: data.sizeX, height: data.sizeY, position: 'absolute', top: data.y, left: data.x, backgroundColor: "rgba(var(--color-1), 0.5)" } :data.by==="ASSISTANT" ? { width: data.sizeX, height: data.sizeY, position: 'absolute', top: data.y, left: data.x, backgroundColor: "rgba(var(--color-22), 0.5)" } : { width: data.sizeX, height: data.sizeY, position: 'absolute', top: data.y, left: data.x }} className={this.state.edit ? "card-properties cursor" : "card-properties no-cursor"}>
                                                        <Card.Body className="carta" data-tip data-for={"img-" + index}>
                                                            {this.state.edit &&
                                                                <div className="card-edit" onClick={this.onEdit.bind(this, data)}>
                                                                    <FontAwesomeIcon icon={faPenToSquare} />
                                                                </div>}

                                                            {data.file !== "" &&
                                                                <img className="imagen no-cursor" src={"https://tallervirtualarchivos.s3.amazonaws.com/" + data.file} alt="" />
                                                            }


                                                            {data.comment === "" ?
                                                                <></>
                                                                :
                                                                <ReactTooltip id={"img-" + index}>
                                                                    {data.comment}
                                                                </ReactTooltip>
                                                            }
                                                        </Card.Body>
                                                    </Card>
                                                    :
                                                    data.type === "Texto" ?
                                                        <Card style={data.by==="TEACHER" ? { width: data.sizeX, height: data.sizeY, position: 'absolute', top: data.y, left: data.x, backgroundColor: "rgba(var(--color-1), 0.5)" } :data.by==="ASSISTANT" ? { width: data.sizeX, height: data.sizeY, position: 'absolute', top: data.y, left: data.x, backgroundColor: "rgba(var(--color-22), 0.5)" } : { width: data.sizeX, height: data.sizeY, position: 'absolute', top: data.y, left: data.x }} className={this.state.edit ? "card-properties cursor" : "card-properties no-cursor"}>
                                                            <Card.Body className="carta" style={{overflowY:"auto"}}>
                                                                {this.state.edit &&
                                                                    <div className="card-edit" onClick={this.onEdit.bind(this, data)}>
                                                                        <FontAwesomeIcon icon={faPenToSquare} />
                                                                    </div>}

                                                                {data.text}
                                                            </Card.Body>
                                                        </Card>
                                                        :
                                                        data.type === "Video" ?
                                                            <Card style={data.by==="TEACHER" ? { width: data.sizeX, height: data.sizeY, position: 'absolute', top: data.y, left: data.x, backgroundColor: "rgba(var(--color-1), 0.5)" } :data.by==="ASSISTANT" ? { width: data.sizeX, height: data.sizeY, position: 'absolute', top: data.y, left: data.x, backgroundColor: "rgba(var(--color-22), 0.5)" } : { width: data.sizeX, height: data.sizeY, position: 'absolute', top: data.y, left: data.x }} className={this.state.edit ? "card-properties cursor" : "card-properties no-cursor"}>
                                                                <Card.Body className="carta d-flex justify-content-center" data-tip data-for={"video-" + index}>
                                                                    {this.state.edit &&
                                                                        <div className="card-edit" onClick={this.onEdit.bind(this, data)}>
                                                                            <FontAwesomeIcon icon={faPenToSquare} />
                                                                        </div>}
                                                                    <video className="no-cursor" src={"https://tallervirtualarchivos.s3.amazonaws.com/" + data.file} width="100%" controls></video>
                                                                    {data.comment === "" ?
                                                                        <></>
                                                                        :
                                                                        <ReactTooltip id={"video-" + index}>
                                                                            {data.comment}
                                                                        </ReactTooltip>
                                                                    }
                                                                </Card.Body>
                                                            </Card>
                                                            :
                                                            data.type === "Archivo" ?
                                                                <Card style={data.by==="TEACHER" ? { width: data.sizeX, position: 'absolute', top: data.y, left: data.x, backgroundColor: "rgba(var(--color-1), 0.5)" } : data.by==="ASSISTANT" ? { width: data.sizeX, position: 'absolute', top: data.y, left: data.x, backgroundColor: "rgba(var(--color-22), 0.5)" } :{ width: data.sizeX, position: 'absolute', top: data.y, left: data.x }} className={this.state.edit ? "card-properties cursor" : "card-properties no-cursor"}>
                                                                    <Card.Body className="carta" data-tip data-for={"archivo-" + index}>
                                                                        {this.state.edit &&
                                                                            <div className="card-edit" onClick={this.onEdit.bind(this, data)}>
                                                                                <FontAwesomeIcon icon={faPenToSquare} />
                                                                            </div>}
                                                                        <Row style={{ marginTop: "calc((" + data.sizeY + " - 60px - 5% - 5%) / 2)", position: "relative" }}>
                                                                            <Col style={{ width: "55px", flex: "0 0 auto" }}><img className=" no-cursor" width={50} src={"../../../fileIcon/" + extension + ".png"} alt="archivo" /></Col>
                                                                            <Col className="col-name no-cursor" style={{ width: "calc(" + data.sizeX + " - 55px - 5% - 5% - 20px)" }}><p className={this.state.edit ? "cursor" : "no-cursor"}>{name}</p></Col>
                                                                        </Row>
                                                                        <div className="icon-file">
                                                                            <a href={"https://tallervirtualarchivos.s3.amazonaws.com/" + data.file} target="_blank"><FontAwesomeIcon icon={faDownload} /></a>
                                                                        </div>
                                                                        {data.comment === "" ?
                                                                            <></>
                                                                            :
                                                                            <ReactTooltip id={"archivo-" + index}>
                                                                                {data.comment}
                                                                            </ReactTooltip>
                                                                        }
                                                                    </Card.Body>
                                                                </Card>
                                                                :
                                                                <></>}
                                            </Draggable>

                                        )
                                    })}
                                </div>
                            </div>
                            <Modal show={this.state.show} onHide={() => { this.setState({ show: false }) }} centered backdrop="static" className="MoodboardTeacher" style={{ color: "rgb(var(--color-22))" }}>
                                <Modal.Header>
                                    <Modal.Title>
                                        {this.state.id ? "Editar tarjeta" : "Nueva tarjeta"}
                                        <div className="close-card">
                                            <FontAwesomeIcon icon={faXmark} title="Cancelar" onClick={() => { if (this.state.isSaving | this.state.isDelete) { } else { this.setState({ show: false, newCardFile: "", newCardSizeTitle: "Pequeño", newCardSizeX: "150px", newCardSizeY: "100px", isLoadingFile: null, loadingFile: null, parallelUploads3: null, newCardText: "", newCardType: "Texto", newCardComentario: "", id: null }) } }} />
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row>
                                        <Col><p>Tipo</p></Col>
                                        <Col>
                                            <DropdownButton disabled={this.state.isSaving} variant="secondary" title={this.state.newCardType}>
                                                <Dropdown.Item onClick={(e) => { this.setState({ newCardType: "Texto", newCardFile: "", isLoadingFile: null, loadingFile: null, parallelUploads3: null, newCardText: "" }) }}>Texto</Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => { this.setState({ newCardType: "Imagen", textH4: "Arrastra aquí una imagen", newCardFile: "", isLoadingFile: null, loadingFile: null, parallelUploads3: null, newCardText: "", newCardComentario: "" }) }}>Imagen</Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => { this.setState({ newCardType: "Video", textH4: "Arrastra aquí un video", newCardFile: "", isLoadingFile: null, loadingFile: null, parallelUploads3: null, newCardText: "", newCardComentario: "" }) }}>Video</Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => { this.setState({ newCardType: "Archivo", textH4: "Arrastra aquí un archivo", newCardFile: "", isLoadingFile: null, loadingFile: null, parallelUploads3: null, newCardText: "", newCardComentario: "" }) }}>Archivo</Dropdown.Item>
                                            </DropdownButton>
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col><p>Tamaño</p></Col>
                                        <Col>
                                            <DropdownButton disabled={this.state.isSaving} variant="secondary" title={this.state.newCardSizeTitle}>
                                                <Dropdown.Item onClick={() => { this.setState({ newCardSizeTitle: "Pequeño", newCardSizeX: "150px", newCardSizeY: "100px" }) }}>Pequeño</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { this.setState({ newCardSizeTitle: "Mediano", newCardSizeX: "270px", newCardSizeY: "170px" }) }}>Mediano</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { this.setState({ newCardSizeTitle: "Grande", newCardSizeX: "380px", newCardSizeY: "230px" }) }}>Grande</Dropdown.Item>
                                            </DropdownButton>
                                        </Col>
                                    </Row>
                                    {this.state.newCardType !== "Texto" ?
                                        <Row className="mt-3">
                                            <Col><p>Comentario</p></Col>
                                            <Col>
                                                <FormControl disabled={this.state.isSaving} as="textarea" rows={3} value={this.state.newCardComentario} onChange={(e) => { this.setState({ newCardComentario: e.target.value }) }} />
                                            </Col>
                                        </Row>
                                        :
                                        <></>}
                                    <Row>
                                        {this.state.newCardType === "Texto" ?
                                            <Col className="mt-3">
                                                <FormControl disabled={this.state.isSaving} as="textarea" rows={3} value={this.state.newCardText} onChange={(e) => { this.setState({ newCardText: e.target.value }) }} />
                                            </Col>
                                            :
                                            <Col className="mt-3">
                                                {this.state.newCardFile === "" ?
                                                    <div
                                                        className="drop-area"
                                                        id="drop-area-file"
                                                        onDragOver={(e) => {
                                                            e.preventDefault()
                                                            let dropArea = document.getElementById("drop-area-file")
                                                            this.setState({ textH4: this.state.newCardType === "Imagen" ? "Suelta para subir una imagen" : this.state.newCardType === "Video" ? "Suelta para subir un video" : "Suelta para subir un archivo" })
                                                            dropArea.classList.add("active")
                                                        }}
                                                        onDragLeave={(e) => {
                                                            e.preventDefault()
                                                            let dropArea = document.getElementById("drop-area-file")
                                                            this.setState({ textH4: this.state.newCardType === "Imagen" ? "Arrastra aquí una imagen" : this.state.newCardType === "Video" ? "Arrastra aquí un video" : "Arrastra aquí un archivo" })
                                                            dropArea.classList.remove("active")
                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault()
                                                            let dropArea = document.getElementById("drop-area-file")
                                                            this.setState({ textH4: this.state.newCardType === "Imagen" ? "Arrastra aquí una imagen" : this.state.newCardType === "Video" ? "Arrastra aquí un video" : "Arrastra aquí un archivo" })
                                                            if (e.dataTransfer.files[0]) {
                                                                if (this.state.newCardType === "Imagen") {
                                                                    if (e.dataTransfer.files[0].type.includes("image/")) {

                                                                        let date = this.state.date
                                                                        let student = this.props.user
                                                                        let key = student.id + "/" + date + "/moodboard/imagen/" + Date.now() + "/" + e.dataTransfer.files[0].name

                                                                        this.setState({ newCardFile: key, isLoadingFile: true, loadingFile: { processing: true } })

                                                                        this.uploadS3(e.dataTransfer.files[0], key, (data) => {
                                                                            this.setState({ loadingFile: { value: data.loaded, total: data.total } })
                                                                        }).then((res) => {
                                                                            console.log(res);
                                                                            this.setState({ isLoadingFile: false })
                                                                        })

                                                                    }
                                                                } else {
                                                                    if (this.state.newCardType === "Video") {
                                                                        if (e.dataTransfer.files[0].type.includes("video/")) {

                                                                            let date = this.state.date
                                                                            let student = this.props.user
                                                                            let key = student.id + "/" + date + "/moodboard/video/" + Date.now() + "/" + e.dataTransfer.files[0].name

                                                                            this.setState({ newCardFile: key, isLoadingFile: true, loadingFile: { processing: true } })

                                                                            this.uploadS3(e.dataTransfer.files[0], key, (data) => {
                                                                                this.setState({ loadingFile: { value: data.loaded, total: data.total } })
                                                                            }).then((res) => {
                                                                                console.log(res);
                                                                                this.setState({ isLoadingFile: false })
                                                                            })

                                                                        }
                                                                    } else {
                                                                        let date = this.state.date
                                                                        let student = this.props.user
                                                                        let key = student.id + "/" + date + "/moodboard/archivo/" + Date.now() + "/" + e.dataTransfer.files[0].name

                                                                        this.setState({ newCardFile: key, isLoadingFile: true, loadingFile: { processing: true } })

                                                                        this.uploadS3(e.dataTransfer.files[0], key, (data) => {
                                                                            this.setState({ loadingFile: { value: data.loaded, total: data.total } })
                                                                        }).then((res) => {
                                                                            console.log(res);
                                                                            this.setState({ isLoadingFile: false })
                                                                        })
                                                                    }
                                                                }
                                                            }
                                                            dropArea.classList.remove("active")
                                                        }}
                                                    >
                                                        <h4>{this.state.textH4}</h4>
                                                        <p>O</p>
                                                        <Button
                                                            className="mt-2"
                                                            variant="secondary"
                                                            disabled={this.state.isSaving}
                                                            onClick={(e) => {
                                                                let input = document.getElementById("inputFile")
                                                                input.click()
                                                            }}
                                                        >
                                                            Selecciona una imagen
                                                        </Button>
                                                        <input
                                                            id="inputFile"
                                                            type="file"
                                                            hidden
                                                            value={""}
                                                            accept={this.state.newCardType === "Imagen" ? "image/*" : this.state.newCardType === "Video" ? "video/*" : "*"}
                                                            disabled={this.state.isSaving}
                                                            onChange={(e) => {
                                                                let dropArea = document.getElementById("drop-area-file")
                                                                dropArea.classList.add("active")
                                                                console.log(e.target.files[0].type);
                                                                if (e.target.files[0]) {
                                                                    if (this.state.newCardType === "Imagen") {
                                                                        if (e.target.files[0].type.includes("image/")) {
                                                                            let date = this.state.date
                                                                            let student = this.props.user
                                                                            let key = student.id + "/" + date + "/moodboard/imagen/" + Date.now() + "/" + e.target.files[0].name

                                                                            this.setState({ newCardFile: key, isLoadingFile: true, loadingFile: { processing: true } })

                                                                            this.uploadS3(e.target.files[0], key, (data) => {
                                                                                this.setState({ loadingFile: { value: data.loaded, total: data.total } })
                                                                            }).then((res) => {
                                                                                console.log(res);
                                                                                this.setState({ isLoadingFile: false })
                                                                            })
                                                                        }
                                                                    } else {
                                                                        if (this.state.newCardType === "Video") {
                                                                            if (e.target.files[0].type.includes("video/")) {
                                                                                let date = this.state.date
                                                                                let student = this.props.user
                                                                                let key = student.id + "/" + date + "/moodboard/video/" + Date.now() + "/" + e.target.files[0].name

                                                                                this.setState({ newCardFile: key, isLoadingFile: true, loadingFile: { processing: true } })

                                                                                this.uploadS3(e.target.files[0], key, (data) => {
                                                                                    this.setState({ loadingFile: { value: data.loaded, total: data.total } })
                                                                                }).then((res) => {
                                                                                    console.log(res);
                                                                                    this.setState({ isLoadingFile: false })
                                                                                })
                                                                            }
                                                                        } else {
                                                                            let date = this.state.date
                                                                            let student = this.props.user
                                                                            let key = student.id + "/" + date + "/moodboard/archivo/" + Date.now() + "/" + e.target.files[0].name

                                                                            this.setState({ newCardFile: key, isLoadingFile: true, loadingFile: { processing: true } })

                                                                            this.uploadS3(e.target.files[0], key, (data) => {
                                                                                this.setState({ loadingFile: { value: data.loaded, total: data.total } })
                                                                            }).then((res) => {
                                                                                console.log(res);
                                                                                this.setState({ isLoadingFile: false })
                                                                            })
                                                                        }
                                                                    }
                                                                }

                                                                dropArea.classList.remove("active")
                                                            }}
                                                        />
                                                    </div>
                                                    :
                                                    this.state.isLoadingFile ?
                                                        this.state.loadingFile ?
                                                            this.state.loadingFile.processing ?
                                                                <div style={{ width: 100 }}>
                                                                    <CircularProgressbar
                                                                        value={0}
                                                                        text={`Cargando`}
                                                                        styles={
                                                                            buildStyles({
                                                                                textColor: "#6c757d",
                                                                                pathColor: "#6c757d"
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                                :
                                                                <div style={{ width: 100 }}>
                                                                    <CircularProgressbar
                                                                        value={this.state.loadingFile.value}
                                                                        maxValue={this.state.loadingFile.total}
                                                                        text={`${Math.round(this.state.loadingFile.value * 100 / this.state.loadingFile.total)}%`}
                                                                        styles={
                                                                            buildStyles({
                                                                                textColor: "#6c757d",
                                                                                pathColor: "#6c757d"
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            :
                                                            <></>

                                                        :
                                                        this.state.newCardType === "Imagen" ?
                                                            <div style={{ position: "relative" }}>
                                                                <img width={400} src={"https://tallervirtualarchivos.s3.amazonaws.com/" + this.state.newCardFile} alt="archivo" />

                                                                <div className="icon-file-modal">
                                                                    <FontAwesomeIcon icon={faXmark} onClick={() => { this.setState({ newCardFile: "" }) }} />
                                                                </div>
                                                            </div>


                                                            :
                                                            this.state.newCardType === "Video" ?
                                                                <div style={{ position: "relative" }}>
                                                                    <video src={"https://tallervirtualarchivos.s3.amazonaws.com/" + this.state.newCardFile} width="400" controls></video>
                                                                    <div className="icon-file-modal">
                                                                        <FontAwesomeIcon icon={faXmark} onClick={() => { this.setState({ newCardFile: "" }) }} />
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div style={{ position: "relative" }}>
                                                                    <img width={50} src={"../../../fileIcon/" + extension + ".png"} alt="archivo" />
                                                                    <p>{name}</p>
                                                                    <div className="icon-file-modal">
                                                                        <FontAwesomeIcon icon={faXmark} onClick={() => { this.setState({ newCardFile: "" }) }} />
                                                                    </div>
                                                                </div>



                                                }
                                            </Col>
                                        }
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    {
                                        this.state.id ?
                                            <Button variant="secondary" disabled={this.state.isSaving | this.state.isDelete} onClick={this.deleteCard}>
                                                {this.state.isDelete ?
                                                    <Loading disabledAnimation />
                                                    :
                                                    "Eliminar"}
                                            </Button>
                                            :
                                            <></>
                                    }

                                    <Button variant="warning" disabled={this.state.isSaving | this.state.isDelete} onClick={this.saveCard}>
                                        {this.state.isSaving ?
                                            <Loading disabledAnimation />
                                            :
                                            "Guardar"}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                        :
                        <></>
                }

                <div className="container-info">
                    <h2 className="title">Moodboard favoritos</h2>
                    <Row className="row-center">

                        {this.state.isLoadingMoodboars ?
                            <Loading disabledAnimation />
                            :
                            this.state.favMoodboars.length === 0 ?
                                <p>No tienes Moodboards favoritos</p>
                                :
                                this.state.favMoodboars.map((data, index) => (
                                    <Col key={"colfav " + index} className="col-moodboard">
                                        <div style={{ cursor: "pointer" }} onClick={this.newMoodboard.bind(this, data, true)}>
                                            {data.student.urlImg ? <img width={50} height={50} style={{ objectFit: "cover", borderRadius: "50%" }} src={"https://tallervirtualarchivos.s3.amazonaws.com/" + data.student.urlImg} alt="foto perfil" /> : <FontAwesomeIcon icon={faUser} style={{ fontSize: "40px" }} />}
                                            <p>{data.student.name}</p>
                                        </div>
                                    </Col>
                                ))}

                    </Row>
                </div>

                <div className="container-info">
                    <h2 className="title">Otros moodboards</h2>
                    <Row className="row-center">

                        {this.state.isLoadingMoodboars ?
                            <Loading disabledAnimation />
                            :
                            this.state.otherMoodboars.length === 0 ?
                                <p>No hay otros Moodboards</p>
                                :
                                this.state.otherMoodboars.map((data, index) => (
                                    <Col key={"colfav " + index} className="col-moodboard">
                                        <div style={{ cursor: "pointer" }} onClick={this.newMoodboard.bind(this, data, false)}>
                                            {data.student.urlImg ? <img width={50} height={50} style={{ objectFit: "cover", borderRadius: "50%" }} src={"https://tallervirtualarchivos.s3.amazonaws.com/" + data.student.urlImg} alt="foto perfil" /> : <FontAwesomeIcon icon={faUser} style={{ fontSize: "40px" }} />}
                                            <p>{data.student.name}</p>
                                        </div>
                                    </Col>
                                ))}
                    </Row>
                </div>
            </div>
        )
    }
}

export default withParams(MoodboardTeacher);