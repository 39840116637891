import React, { Component } from "react"
import { Col, Form, Row, Button, FormControl, Modal } from "react-bootstrap"
import { Upload } from '@aws-sdk/lib-storage';
import { S3Client, DeleteObjectCommand } from '@aws-sdk/client-s3';
import { useParams } from "react-router-dom"
import { Api } from "../../AWS/aws"
import Loading from "../../Components/Loading"
import listExtension from "../../Assets/Js/listExtension"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { faEdit, faEye, faSave } from "@fortawesome/free-regular-svg-icons";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Editor } from '@tinymce/tinymce-react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Swal from 'sweetalert2'
import "./BriefTeacher.css"

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}
class BriefTeacher extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: Date.now(),
            project: false,
            isLoading: true,
            isSaving: false,

            isLoadingNote: false,
            newNameEvent: "",
            newDateEvent: "",
            newDescriptionEvent: "",
            show: false,


            isEditDescription: false,
            isEditStructure: false,
            isEditExample: false,
            isEditPattern: false,
            isEditCourseContents: false,

            editorRefDescription: React.createRef(null),
            editorRefStructure: React.createRef(null),
            editorRefExample: React.createRef(null),
            editorRefPattern: React.createRef(null),
            editorRefCourseContents: React.createRef(null),

            loadingFileDescription: [],
            loadingFileStructure: [],
            loadingFileExample: [],
            loadingFilePattern: [],
            loadingFileCourseContents: [],

            auxFileDescription: [],
            auxFileStructure: [],
            auxFileExample: [],
            auxFilePattern: [],
            auxFileCourseContents: [],

            auxLinkVideoDescription: "",
            auxLinkVideoStructure: "",
            auxLinkVideoExample: "",
            auxLinkVideoPattern: "",
            auxLinkVideoCourseContents: "",

            parallelUploads3Description: [],
            parallelUploads3Structure: [],
            parallelUploads3Example: [],
            parallelUploads3Pattern: [],
            parallelUploads3CourseContents: [],

            isEditName: false,
            newName: "",

            projectAux: false,

            notes: [],
            viewNotes: [],
            isLoadingNotes: true,
            value: new Date()
        }
    }

    getProjectDescription = (id) => {
        return Api({
            path: `/project/${id}`
        })
    }

    postProjectDescription = (body) => {
        return Api({
            path: `/project`,
            method: "POST",
            body: body
        })
    }

    getNotes = (id) => {
        return Api({
            path: `/project/student/notes/${id}`
        })
    }

    postNotes = (body) => {
        return Api({
            path: `/project/student/notes`,
            body: body,
            method: "POST"
        })
    }

    componentDidMount() {
        let id = this.props.params.id
        this.getProjectDescription(id).then((res) => {
            if (res.code === 0) {
                this.setState({ project: res.body, isLoading: false })
            } else {
                this.setState({ isLoading: false })
            }
        })
        this.getNotes(id).then((res) => {
            if (res.code === 0) {
                let newViewNotes = []
                res.body.forEach(element => {
                    let date = new Date(element.date)
                    if (this.state.value.toISOString().split("T")[0] === date.toISOString().split("T")[0]) {
                        newViewNotes.push(element)
                    }
                });

                this.setState({ isLoadingNotes: false, viewNotes: newViewNotes, notes: res.body })

            }
        })
    }


    handleClose = () => {
        this.setState({ show: false, newDateEvent: "", newDescriptionEvent: "", newNameEvent: "" })
    };
    handleShow = () => this.setState({ show: true });

    handleClick = () => {
        if (this.state.newNameEvent === "") {

            Swal.fire({
                icon: 'error',
                text: 'No puede dejar el nombre en blanco!',
                confirmButtonColor:"#dc3545"
            })

        } else {
            if (this.state.newDateEvent === "") {

                Swal.fire({
                    icon: 'error',
                    text: 'Tiene que seleccionar una fecha!',
                    confirmButtonColor:"#dc3545"
                })

            } else {
                this.setState({ isLoadingNote: true })
                let body = {
                    name: this.state.newNameEvent,
                    description: this.state.newDescriptionEvent,
                    date: this.state.newDateEvent,
                    idProject: this.props.params.id
                }

                this.postNotes(body).then((res) => {
                    console.log(res);
                    if (res.code === 0) {
                        let arr = this.state.notes
                        arr.push(res.body)

                        if (this.state.value.toISOString().split("T")[0] === res.body.date.split("T")[0]) {

                            let arr2 = this.state.viewNotes
                            arr2.push(res.body)
                            this.setState({ viewNotes: arr2 })
                        }

                        Swal.fire({
                            icon: 'success',
                            text: 'Evento creado exitosamente!',
                            confirmButtonColor:"#ffc107"
                        })

                        this.setState({ notes: arr, isLoadingNote: false })
                        this.handleClose()


                    }
                })
            }
        }

    };

    onChange = (data) => {
        let newViewNotes = []
        this.state.notes.forEach(element => {
            let date = new Date(element.date)
            if (data.toISOString().split("T")[0] === date.toISOString().split("T")[0]) {
                newViewNotes.push(element)
            }
        });
        this.setState({ viewNotes: newViewNotes, value: data })
    }


    eventDay = () => {
        let arr = this.state.value.toISOString().split("T")
        arr = arr[0].split("-")
        return arr[2] + "/" + arr[1] + "/" + arr[0]
    }

    uploadS3 = async (file, key, fun, from) => {

        const target = {
            Bucket: "tallervirtualarchivos",
            Key: key,
            Body: file,
            ACL: "public-read",
            ContentType: file.type
        }
        const creds = {
            accessKeyId: "AKIA2T5OXYWGGXOKREU2",
            secretAccessKey: "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",
        }
        try {
            const parallelUploads3 = new Upload({
                client: new S3Client({
                    region: "us-east-1",
                    credentials: creds
                }),
                partSize: 1024 * 1024 * 20,
                params: target,
            })

            let projectAux = this.state.project

            if (from === "Detalle") {
                let arr = this.state.parallelUploads3Description
                let pos = projectAux.filesDescription.indexOf(key)
                if (pos !== -1) {
                    arr[pos] = parallelUploads3
                    this.setState({ parallelUploads3Description: arr })
                }
            }

            if (from === "Contenidos") {
            }
            if (from === "Ejemplos") {
            }
            if (from === "Estructura") {
            }
            if (from === "Pauta") {
            }

            parallelUploads3.on("httpUploadProgress", (process) => {
                fun(process)
            })

            await parallelUploads3.done()
            return true
        } catch (e) {
            console.log(e);
            return false
        }
    }

    deleteS3 = (key) => {

        const target = {
            Bucket: "tallervirtualarchivos",
            Key: key
        }

        const creds = {
            accessKeyId: "AKIA2T5OXYWGGXOKREU2",
            secretAccessKey: "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",
        }

        const client = new S3Client({
            region: "us-east-1",
            credentials: creds
        })

        const command = new DeleteObjectCommand(target)

        client.send(command).then((res) => {
            console.log(res);
        })
    }

    deleteDescription = (key, i) => {

        let loadingFileDescription = this.state.loadingFileDescription

        if (loadingFileDescription[i]) {
            if (loadingFileDescription[i].processing) {
                //se esta cargando archivo
                let arr = this.state.parallelUploads3Description
                arr[i].abort()
                arr.splice(i, 1)
                this.setState({ parallelUploads3Description: arr })

            } else {
                if (loadingFileDescription[i].value === loadingFileDescription[i].total) {
                    //ya se cargo el archivo
                    this.deleteS3(key)
                } else {
                    //se esta cargando archivo
                    let arr = this.state.parallelUploads3Description
                    arr[i].abort()
                    arr.splice(i, 1)
                    this.setState({ parallelUploads3Description: arr })
                }
            }
        } else {

        }

    }

    deleteStructure = (key, i) => {

        let loadingFileStructure = this.state.loadingFileStructure

        if (loadingFileStructure[i]) {
            if (loadingFileStructure[i].processing) {
                //se esta cargando archivo
                let arr = this.state.parallelUploads3Structure
                arr[i].abort()
                arr.splice(i, 1)
                this.setState({ parallelUploads3Structure: arr })

            } else {
                if (loadingFileStructure[i].value === loadingFileStructure[i].total) {
                    //ya se cargo el archivo
                    this.deleteS3(key)
                } else {
                    //se esta cargando archivo
                    let arr = this.state.parallelUploads3Structure
                    arr[i].abort()
                    arr.splice(i, 1)
                    this.setState({ parallelUploads3Structure: arr })
                }
            }
        } else {

        }

    }

    deleteExample = (key, i) => {

        let loadingFileExample = this.state.loadingFileExample

        if (loadingFileExample[i]) {
            if (loadingFileExample[i].processing) {
                //se esta cargando archivo
                let arr = this.state.parallelUploads3Example
                arr[i].abort()
                arr.splice(i, 1)
                this.setState({ parallelUploads3Example: arr })

            } else {
                if (loadingFileExample[i].value === loadingFileExample[i].total) {
                    //ya se cargo el archivo
                    this.deleteS3(key)
                } else {
                    //se esta cargando archivo
                    let arr = this.state.parallelUploads3Example
                    arr[i].abort()
                    arr.splice(i, 1)
                    this.setState({ parallelUploads3Example: arr })
                }
            }
        } else {

        }

    }

    deletePattern = (key, i) => {

        let loadingFilePattern = this.state.loadingFilePattern

        if (loadingFilePattern[i]) {
            if (loadingFilePattern[i].processing) {
                //se esta cargando archivo
                let arr = this.state.parallelUploads3Pattern
                arr[i].abort()
                arr.splice(i, 1)
                this.setState({ parallelUploads3Pattern: arr })

            } else {
                if (loadingFilePattern[i].value === loadingFilePattern[i].total) {
                    //ya se cargo el archivo
                    this.deleteS3(key)
                } else {
                    //se esta cargando archivo
                    let arr = this.state.parallelUploads3Pattern
                    arr[i].abort()
                    arr.splice(i, 1)
                    this.setState({ parallelUploads3Pattern: arr })
                }
            }
        } else {

        }

    }

    deleteCourseContents = (key, i) => {

        let loadingFileCourseContents = this.state.loadingFileCourseContents

        if (loadingFileCourseContents[i]) {
            if (loadingFileCourseContents[i].processing) {
                //se esta cargando archivo
                let arr = this.state.parallelUploads3CourseContents
                arr[i].abort()
                arr.splice(i, 1)
                this.setState({ parallelUploads3CourseContents: arr })

            } else {
                if (loadingFileCourseContents[i].value === loadingFileCourseContents[i].total) {
                    //ya se cargo el archivo
                    this.deleteS3(key)
                } else {
                    //se esta cargando archivo
                    let arr = this.state.parallelUploads3CourseContents
                    arr[i].abort()
                    arr.splice(i, 1)
                    this.setState({ parallelUploads3CourseContents: arr })
                }
            }
        } else {

        }

    }

    filesDescription = (eventFiles) => {
        let date = this.state.date
        let projectAux = this.state.project
        let teacher = this.props.user

        for (let index = 0; index < eventFiles.length; index++) {
            let key = teacher.id + "/" + date + "/detalle/" + eventFiles[index].name
            if (projectAux.filesDescription.indexOf(key) < 0) {
                projectAux.filesDescription.push(key)
                let loadingAux = this.state.loadingFileDescription

                loadingAux[projectAux.filesDescription.indexOf(key)] = { processing: true }
                this.setState({ loadingFileDescription: loadingAux })

                this.uploadS3(eventFiles[index], key, (data) => {
                    let i = projectAux.filesDescription.indexOf(key)
                    let loadingAux = this.state.loadingFileDescription
                    loadingAux[i] = { value: data.loaded, total: data.total }
                    this.setState({ loadingFileDescription: loadingAux })
                }, "Detalle").then((res) => {
                    console.log(res);
                })
            }
        }

        this.setState({ project: projectAux })
    }

    filesStructure = (eventFiles) => {
        let date = this.state.date
        let projectAux = this.state.project
        let teacher = this.props.user

        for (let index = 0; index < eventFiles.length; index++) {
            let key = teacher.id + "/" + date + "/detalle/" + eventFiles[index].name
            if (projectAux.filesStructure.indexOf(key) < 0) {
                projectAux.filesStructure.push(key)
                let loadingAux = this.state.loadingFileStructure

                loadingAux[projectAux.filesStructure.indexOf(key)] = { processing: true }
                this.setState({ loadingFileStructure: loadingAux })

                this.uploadS3(eventFiles[index], key, (data) => {
                    let i = projectAux.filesStructure.indexOf(key)
                    let loadingAux = this.state.loadingFileStructure
                    loadingAux[i] = { value: data.loaded, total: data.total }
                    this.setState({ loadingFileStructure: loadingAux })
                }, "Detalle").then((res) => {
                    console.log(res);
                })
            }
        }

        this.setState({ project: projectAux })
    }

    filesExample = (eventFiles) => {
        let date = this.state.date
        let projectAux = this.state.project
        let teacher = this.props.user

        for (let index = 0; index < eventFiles.length; index++) {
            let key = teacher.id + "/" + date + "/detalle/" + eventFiles[index].name
            if (projectAux.filesExample.indexOf(key) < 0) {
                projectAux.filesExample.push(key)
                let loadingAux = this.state.loadingFileExample

                loadingAux[projectAux.filesExample.indexOf(key)] = { processing: true }
                this.setState({ loadingFileExample: loadingAux })

                this.uploadS3(eventFiles[index], key, (data) => {
                    let i = projectAux.filesExample.indexOf(key)
                    let loadingAux = this.state.loadingFileExample
                    loadingAux[i] = { value: data.loaded, total: data.total }
                    this.setState({ loadingFileExample: loadingAux })
                }, "Detalle").then((res) => {
                    console.log(res);
                })
            }
        }

        this.setState({ project: projectAux })
    }

    filesPattern = (eventFiles) => {
        let date = this.state.date
        let projectAux = this.state.project
        let teacher = this.props.user

        for (let index = 0; index < eventFiles.length; index++) {
            let key = teacher.id + "/" + date + "/detalle/" + eventFiles[index].name
            if (projectAux.filesPattern.indexOf(key) < 0) {
                projectAux.filesPattern.push(key)
                let loadingAux = this.state.loadingFilePattern

                loadingAux[projectAux.filesPattern.indexOf(key)] = { processing: true }
                this.setState({ loadingFilePattern: loadingAux })

                this.uploadS3(eventFiles[index], key, (data) => {
                    let i = projectAux.filesPattern.indexOf(key)
                    let loadingAux = this.state.loadingFilePattern
                    loadingAux[i] = { value: data.loaded, total: data.total }
                    this.setState({ loadingFilePattern: loadingAux })
                }, "Detalle").then((res) => {
                    console.log(res);
                })
            }
        }

        this.setState({ project: projectAux })
    }

    filesCourseContents = (eventFiles) => {
        let date = this.state.date
        let projectAux = this.state.project
        let teacher = this.props.user

        for (let index = 0; index < eventFiles.length; index++) {
            let key = teacher.id + "/" + date + "/detalle/" + eventFiles[index].name
            if (projectAux.filesCourseContents.indexOf(key) < 0) {
                projectAux.filesCourseContents.push(key)
                let loadingAux = this.state.loadingFileCourseContents

                loadingAux[projectAux.filesCourseContents.indexOf(key)] = { processing: true }
                this.setState({ loadingFileCourseContents: loadingAux })

                this.uploadS3(eventFiles[index], key, (data) => {
                    let i = projectAux.filesCourseContents.indexOf(key)
                    let loadingAux = this.state.loadingFileCourseContents
                    loadingAux[i] = { value: data.loaded, total: data.total }
                    this.setState({ loadingFileCourseContents: loadingAux })
                }, "Detalle").then((res) => {
                    console.log(res);
                })
            }
        }

        this.setState({ project: projectAux })
    }

    renderImgFileDescription = (ruta, index) => {

        let loadingFileDescription = this.state.loadingFileDescription

        if (loadingFileDescription[index]) {
            if (loadingFileDescription[index].processing) {
                return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
            } else {
                if (loadingFileDescription[index].value === loadingFileDescription[index].total) {
                    return (<img width={50} src={ruta} alt="archivo" />)
                } else {
                    return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
                }
            }
        } else {
            return (<img width={50} src={ruta} alt="archivo" />)
        }

    }

    renderImgFileStructure = (ruta, index) => {

        let loadingFileStructure = this.state.loadingFileStructure

        if (loadingFileStructure[index]) {
            if (loadingFileStructure[index].processing) {
                return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
            } else {
                if (loadingFileStructure[index].value === loadingFileStructure[index].total) {
                    return (<img width={50} src={ruta} alt="archivo" />)
                } else {
                    return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
                }
            }
        } else {
            return (<img width={50} src={ruta} alt="archivo" />)
        }

    }

    renderImgFileExample = (ruta, index) => {

        let loadingFileExample = this.state.loadingFileExample

        if (loadingFileExample[index]) {
            if (loadingFileExample[index].processing) {
                return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
            } else {
                if (loadingFileExample[index].value === loadingFileExample[index].total) {
                    return (<img width={50} src={ruta} alt="archivo" />)
                } else {
                    return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
                }
            }
        } else {
            return (<img width={50} src={ruta} alt="archivo" />)
        }

    }

    renderImgFilePattern = (ruta, index) => {

        let loadingFilePattern = this.state.loadingFilePattern

        if (loadingFilePattern[index]) {
            if (loadingFilePattern[index].processing) {
                return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
            } else {
                if (loadingFilePattern[index].value === loadingFilePattern[index].total) {
                    return (<img width={50} src={ruta} alt="archivo" />)
                } else {
                    return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
                }
            }
        } else {
            return (<img width={50} src={ruta} alt="archivo" />)
        }

    }

    renderImgFileCourseContents = (ruta, index) => {

        let loadingFileCourseContents = this.state.loadingFileCourseContents

        if (loadingFileCourseContents[index]) {
            if (loadingFileCourseContents[index].processing) {
                return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
            } else {
                if (loadingFileCourseContents[index].value === loadingFileCourseContents[index].total) {
                    return (<img width={50} src={ruta} alt="archivo" />)
                } else {
                    return (<img className="mi-imagen-gris" width={50} src={ruta} alt="archivo" />)
                }
            }
        } else {
            return (<img width={50} src={ruta} alt="archivo" />)
        }

    }



    render() {
        let linkVideoDescription = []
        let linkVideoStructure = []
        let linkVideoExample = []
        let linkVideoPattern = []
        let linkVideoCourseContents = []

        if (this.state.project.linkVideoDescription && this.state.project.linkVideoDescription !== "") {
            linkVideoDescription = this.state.project.linkVideoDescription.split(",")
        }
        if (this.state.project.linkVideoStructure && this.state.project.linkVideoStructure !== "") {
            linkVideoStructure = this.state.project.linkVideoStructure.split(",")
        }
        if (this.state.project.linkVideoExample && this.state.project.linkVideoExample !== "") {
            linkVideoExample = this.state.project.linkVideoExample.split(",")
        }
        if (this.state.project.linkVideoPattern && this.state.project.linkVideoPattern !== "") {
            linkVideoPattern = this.state.project.linkVideoPattern.split(",")
        }
        if (this.state.project.linkVideoCourseContents && this.state.project.linkVideoCourseContents !== "") {
            linkVideoCourseContents = this.state.project.linkVideoCourseContents.split(",")
        }

        let isEditName = this.state.isEditName
        let isLoading = this.state.isLoading
        let newName = this.state.newName
        let project = this.state.project
        let isEditDescription = this.state.isEditDescription
        let editorRefDescription = this.state.editorRefDescription
        let loadingFileDescription = this.state.loadingFileDescription
        let isEditStructure = this.state.isEditStructure
        let editorRefStructure = this.state.editorRefStructure
        let loadingFileStructure = this.state.loadingFileStructure
        let isEditExample = this.state.isEditExample
        let editorRefExample = this.state.editorRefExample
        let loadingFileExample = this.state.loadingFileExample
        let isEditPattern = this.state.isEditPattern
        let editorRefPattern = this.state.editorRefPattern
        let loadingFilePattern = this.state.loadingFilePattern
        let isEditCourseContents = this.state.isEditCourseContents
        let editorRefCourseContents = this.state.editorRefCourseContents
        let loadingFileCourseContents = this.state.loadingFileCourseContents

        let isLoadingNotes = this.state.isLoadingNotes
        let viewNotes = this.state.viewNotes

        return (
            <div className="BriefTeacher">
                <Row>
                    <Col className="project-info">
                        <div className="name-project" style={isEditName ? { height: "60px", display: "flex", alignItems: "center" } : {}}>
                            {isLoading ?
                                <div style={{ height: "100%", padding: "8px" }}>
                                    <Loading disabledAnimation />
                                </div>
                                :
                                isEditName ?
                                    <Form.Control autoComplete="off" value={newName} style={{ width: "300px", height: "40px", marginLeft: "20px" }} onChange={(e) => {
                                        this.setState({ newName: e.target.value })
                                    }} />
                                    :
                                    <h1>{project.name}</h1>
                            }
                            {isLoading ?
                                <></>
                                :
                                <div className="edit">
                                    {isEditName ?
                                        <FontAwesomeIcon
                                            icon={faSave}
                                            onClick={() => {
                                                let projectAux = project
                                                projectAux.name = newName
                                                this.setState({ project: projectAux, isEditName: false })
                                            }}
                                        />
                                        :
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            onClick={() => {
                                                this.setState({ newName: project.name, isEditName: true })
                                            }}
                                        />
                                    }
                                </div>
                            }
                        </div>

                        {isLoading ?
                            <></>
                            :
                            <div className="col-info">
                                <div className="container-info">

                                    <div className="sticky">
                                        <h1 className="title">Detalles</h1>

                                        <div className="edit">
                                            {isEditDescription ?
                                                <>
                                                    {this.state.isSaving ?
                                                        <div style={{ display: "inline-block" }}>
                                                            <Loading disabledAnimation size="sm" />
                                                        </div>
                                                        :
                                                        <FontAwesomeIcon
                                                            icon={faSave}
                                                            onClick={() => {
                                                                if (!this.state.isSaving) {
                                                                    this.setState({ isSaving: true })
                                                                    let projectAux = project
                                                                    projectAux.description = editorRefDescription.current.getContent()
                                                                    projectAux.linkVideoDescription = this.state.auxLinkVideoDescription
                                                                    let isLoading = false
                                                                    this.state.loadingFileDescription.forEach(res => {
                                                                        if (res) {
                                                                            if (res.processing) {
                                                                                isLoading = true
                                                                            } else {
                                                                                if (res.value === res.total) {

                                                                                } else {
                                                                                    isLoading = true
                                                                                }
                                                                            }
                                                                        } else {
                                                                            isLoading = true
                                                                        }
                                                                    })
                                                                    if (!isLoading) {
                                                                        let body = {
                                                                            by: "description",
                                                                            files: projectAux.filesDescription,
                                                                            text: projectAux.description,
                                                                            idProject: this.props.params.id,
                                                                            linkVideo: this.state.auxLinkVideoDescription
                                                                        }
                                                                        this.postProjectDescription(body).then(res => {
                                                                            Swal.fire({
                                                                                icon: 'success',
                                                                                text: 'Detalle guardado correctamente!',
                                                                                confirmButtonColor:"#ffc107"
                                                                            })
                                                                            this.setState({ project: projectAux, auxFileDescription: [], isEditDescription: false, projectAux: false, loadingFileDescription: [], parallelUploads3Description: [], isSaving: false })
                                                                        })

                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    }

                                                    <FontAwesomeIcon
                                                        icon={faXmark}
                                                        className="ps-2"
                                                        onClick={() => {
                                                            if (!this.state.isSaving) {
                                                                let projectAux = this.state.projectAux
                                                                projectAux.filesDescription = this.state.auxFileDescription
                                                                this.setState({ project: projectAux, auxFileDescription: [], isEditDescription: false, projectAux: false, loadingFileDescription: [], parallelUploads3Description: [] })
                                                            }
                                                        }}
                                                    />
                                                </>
                                                :
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    onClick={() => {
                                                        if (!this.state.isSaving) {
                                                            if (!isEditCourseContents && !isEditExample && !isEditPattern && !isEditStructure) {
                                                                this.setState({ isEditDescription: true, projectAux: this.state.project, auxFileDescription: [...this.state.project.filesDescription], auxLinkVideoDescription: this.state.project.linkVideoDescription })
                                                            }
                                                        }
                                                    }}
                                                />
                                            }
                                        </div>
                                    </div>

                                    {
                                        isEditDescription ?
                                            <>
                                                <Editor
                                                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                                    onInit={(evt, editor) => {
                                                        let editorRefDescriptionAux = editorRefDescription;
                                                        editorRefDescriptionAux.current = editor;
                                                        this.setState({ editorRefDescription: editorRefDescriptionAux })
                                                    }}
                                                    initialValue={project.description}
                                                    init={{
                                                        height: 400,
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                            'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                                                        ],
                                                        toolbar: 'undo redo | blocks | ' +
                                                            'bold italic forecolor | alignleft aligncenter ' +
                                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                                            'removeformat | help',
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                    }}
                                                    disabled={this.state.isSaving}
                                                />

                                                <div className='my-3'>
                                                    <h4>Link Video (ingresar links separados por comas)</h4>
                                                    <FormControl value={this.state.auxLinkVideoDescription} onChange={(event) => this.setState({ auxLinkVideoDescription: event.target.value })} disabled={this.state.isSaving} />
                                                </div>

                                                <div className='my-3'>
                                                    <Row>
                                                        <Col>
                                                            <div
                                                                className="drop-area"
                                                                id="drop-area-description"
                                                                onDragOver={(e) => {
                                                                    e.preventDefault()
                                                                    let dropArea = document.getElementById("drop-area-description")
                                                                    dropArea.querySelector("h4").textContent = "Suelta para subir tus archivos"
                                                                    dropArea.classList.add("active")
                                                                }}
                                                                onDragLeave={(e) => {
                                                                    e.preventDefault()
                                                                    let dropArea = document.getElementById("drop-area-description")
                                                                    dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                                    dropArea.classList.remove("active")
                                                                }}
                                                                onDrop={(e) => {
                                                                    if (!this.state.isSaving) {
                                                                        e.preventDefault()
                                                                        this.filesDescription(e.dataTransfer.files)
                                                                        let dropArea = document.getElementById("drop-area-description")
                                                                        dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                                        dropArea.classList.remove("active")
                                                                    }

                                                                }}
                                                            >
                                                                <h4 id="h4-description">Arrastra aquí tus archivos</h4>
                                                                <span>O</span>
                                                                <Button
                                                                    className="mt-2"
                                                                    variant="secondary"
                                                                    onClick={(e) => {
                                                                        let input = document.getElementById("inputFileDescription")
                                                                        input.click()
                                                                    }}
                                                                >
                                                                    Selecciona tus archivos
                                                                </Button>
                                                                <input
                                                                    id="inputFileDescription"
                                                                    type="file"
                                                                    multiple
                                                                    hidden
                                                                    value={""}
                                                                    onChange={(e) => {
                                                                        this.filesDescription(e.target.files)
                                                                    }}
                                                                    disabled={this.state.isSaving}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='mb-5'>
                                                    <Row>
                                                        {project.filesDescription.map((data, index) => {
                                                            let arr = data.split("/")
                                                            let name = arr[arr.length - 1]
                                                            arr = name.split(".")
                                                            let extension = arr[arr.length - 1]
                                                            let posExtension = listExtension.indexOf(extension)
                                                            if (posExtension < 0) {
                                                                extension = "archivo"
                                                            }
                                                            return (
                                                                <Col key={"col " + index} className="col-file">
                                                                    <Row className="card-file">
                                                                        <FontAwesomeIcon
                                                                            onClick={(e) => {

                                                                                this.deleteDescription(project.filesDescription[index], index)
                                                                                let projectAux = project
                                                                                let loadingAux = loadingFileDescription
                                                                                projectAux.filesDescription.splice(index, 1)
                                                                                loadingAux.splice(index, 1)
                                                                                this.setState({ project: projectAux, loadingFileDescription: loadingAux })

                                                                            }}
                                                                            className="col-delete"
                                                                            icon={faXmark}
                                                                        />
                                                                        <Col className="col-img">
                                                                            {this.renderImgFileDescription("../../../fileIcon/" + extension + ".png", index)}
                                                                            {loadingFileDescription[index] ?
                                                                                loadingFileDescription[index].processing ?
                                                                                    <div className="col-carga">
                                                                                        <CircularProgressbar
                                                                                            value={0}
                                                                                            text={`Cargando`}
                                                                                            styles={
                                                                                                buildStyles({
                                                                                                    textColor: "#6c757d",
                                                                                                    pathColor: "#6c757d"
                                                                                                })
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    :
                                                                                    loadingFileDescription[index].value === loadingFileDescription[index].total ?
                                                                                        <></>
                                                                                        :
                                                                                        <div className="col-carga">
                                                                                            <CircularProgressbar
                                                                                                value={loadingFileDescription[index].value}
                                                                                                maxValue={loadingFileDescription[index].total}
                                                                                                text={`${Math.round(loadingFileDescription[index].value * 100 / loadingFileDescription[index].total)}%`}
                                                                                                styles={
                                                                                                    buildStyles({
                                                                                                        textColor: "#6c757d",
                                                                                                        pathColor: "#6c757d"
                                                                                                    })
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                :
                                                                                <></>
                                                                            }
                                                                        </Col>
                                                                        <Col className="col-name">{name}</Col>
                                                                    </Row>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </div>
                                            </>
                                            :
                                            <>
                                                {
                                                    project.description !== "" &&
                                                    <div className="text-editor" dangerouslySetInnerHTML={{ __html: project.description }}></div>
                                                }

                                                {
                                                    this.state.project.linkVideoDescription && this.state.project.linkVideoDescription !== "" ?
                                                        <>
                                                            {
                                                                linkVideoDescription.map((res, index) => {
                                                                    if (res !== "") {
                                                                        let link = res.replace("watch?v=", "embed/")
                                                                        return (
                                                                            <div className="m-2" key={"description" + index}>
                                                                                <iframe width="384" height="216" src={link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                            </div>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <></>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                }

                                                {
                                                    project.filesDescription.length > 0 &&
                                                    <div>
                                                        <Row>
                                                            {project.filesDescription.map((data, index) => {
                                                                let arr = data.split("/")
                                                                let name = arr[arr.length - 1]
                                                                arr = name.split(".")
                                                                let extension = arr[arr.length - 1]
                                                                let posExtension = listExtension.indexOf(extension)
                                                                if (posExtension < 0) {
                                                                    extension = "archivo"
                                                                }
                                                                return (
                                                                    <Col key={"col " + index} className="col-file">
                                                                        <Row className="card-file">
                                                                            <a href={"https://tallervirtualarchivos.s3.amazonaws.com/" + data} target="_blank" rel="noreferrer">
                                                                                <FontAwesomeIcon
                                                                                    className="col-view"
                                                                                    icon={faEye}
                                                                                />
                                                                            </a>

                                                                            <Col className="col-img">
                                                                                <img width={50} src={"../../../fileIcon/" + extension + ".png"} alt="archivo" />
                                                                            </Col>

                                                                            <Col className="col-name">{name}</Col>
                                                                        </Row>
                                                                    </Col>
                                                                )
                                                            })}
                                                        </Row>
                                                    </div>
                                                }
                                            </>
                                    }



                                </div>

                                <div className="container-info">

                                    <div className="sticky">
                                        <h1 className="title">Estructura de la entrega</h1>

                                        <div className="edit">
                                            {isEditStructure ?
                                                <>
                                                    {this.state.isSaving ?
                                                        <div style={{ display: "inline-block" }}>
                                                            <Loading disabledAnimation size="sm" />
                                                        </div>
                                                        :
                                                        <FontAwesomeIcon
                                                            icon={faSave}
                                                            onClick={() => {
                                                                if (!this.state.isSaving) {
                                                                    this.setState({ isSaving: true })
                                                                    let projectAux = project
                                                                    projectAux.structure = editorRefStructure.current.getContent()
                                                                    projectAux.linkVideoStructure = this.state.auxLinkVideoStructure
                                                                    let isLoading = false
                                                                    this.state.loadingFileStructure.forEach(res => {
                                                                        if (res) {
                                                                            if (res.processing) {
                                                                                isLoading = true
                                                                            } else {
                                                                                if (res.value === res.total) {

                                                                                } else {
                                                                                    isLoading = true
                                                                                }
                                                                            }
                                                                        } else {
                                                                            isLoading = true
                                                                        }
                                                                    })
                                                                    if (!isLoading) {
                                                                        let body = {
                                                                            by: "structure",
                                                                            files: projectAux.filesStructure,
                                                                            text: projectAux.structure,
                                                                            idProject: this.props.params.id,
                                                                            linkVideo: this.state.auxLinkVideoStructure
                                                                        }
                                                                        this.postProjectDescription(body).then(res => {
                                                                            Swal.fire({
                                                                                icon: 'success',
                                                                                text: 'Estructura de la entrega guardada correctamente!',
                                                                                confirmButtonColor:"#ffc107"
                                                                            })
                                                                            this.setState({ project: projectAux, auxFileStructure: [], isEditStructure: false, projectAux: false, loadingFileStructure: [], parallelUploads3Structure: [], isSaving: false })
                                                                        })

                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    }

                                                    <FontAwesomeIcon
                                                        icon={faXmark}
                                                        className="ps-2"
                                                        onClick={() => {
                                                            if (!this.state.isSaving) {
                                                                let projectAux = this.state.projectAux
                                                                projectAux.filesStructure = this.state.auxFileStructure
                                                                this.setState({ project: projectAux, auxFileStructure: [], isEditStructure: false, projectAux: false, loadingFileStructure: [], parallelUploads3Structure: [] })
                                                            }
                                                        }}
                                                    />
                                                </>
                                                :
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    onClick={() => {
                                                        if (!this.state.isSaving) {
                                                            if (!isEditCourseContents && !isEditDescription && !isEditExample && !isEditPattern) {
                                                                this.setState({ isEditStructure: true, projectAux: this.state.project, auxFileStructure: [...this.state.project.filesStructure], auxLinkVideoStructure: this.state.project.linkVideoStructure })
                                                            }
                                                        }
                                                    }}
                                                />
                                            }
                                        </div>
                                    </div>

                                    {isEditStructure ?
                                        <>
                                            <Editor
                                                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                                onInit={(evt, editor) => {
                                                    let editorRefStructureAux = editorRefStructure;
                                                    editorRefStructureAux.current = editor;
                                                    this.setState({ editorRefStructure: editorRefStructureAux })
                                                }}
                                                initialValue={project.structure}
                                                init={{
                                                    height: 400,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                                                    ],
                                                    toolbar: 'undo redo | blocks | ' +
                                                        'bold italic forecolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                                disabled={this.state.isSaving}
                                            />

                                            <div className='my-3'>
                                                <h4>Link Video (ingresar links separados por comas)</h4>
                                                <FormControl value={this.state.auxLinkVideoStructure} onChange={(event) => this.setState({ auxLinkVideoStructure: event.target.value })} disabled={this.state.isSaving} />
                                            </div>

                                            <div className='my-3'>
                                                <Row>
                                                    <Col>
                                                        <div
                                                            className="drop-area"
                                                            id="drop-area-structure"
                                                            onDragOver={(e) => {
                                                                e.preventDefault()
                                                                let dropArea = document.getElementById("drop-area-structure")
                                                                dropArea.querySelector("h4").textContent = "Suelta para subir tus archivos"
                                                                dropArea.classList.add("active")
                                                            }}
                                                            onDragLeave={(e) => {
                                                                e.preventDefault()
                                                                let dropArea = document.getElementById("drop-area-structure")
                                                                dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                                dropArea.classList.remove("active")
                                                            }}
                                                            onDrop={(e) => {
                                                                if (!this.state.isSaving) {
                                                                    e.preventDefault()
                                                                    this.filesStructure(e.dataTransfer.files)
                                                                    let dropArea = document.getElementById("drop-area-structure")
                                                                    dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                                    dropArea.classList.remove("active")
                                                                }

                                                            }}
                                                        >
                                                            <h4 id="h4-structure">Arrastra aquí tus archivos</h4>
                                                            <span>O</span>
                                                            <Button
                                                                className="mt-2"
                                                                variant="secondary"
                                                                onClick={(e) => {
                                                                    let input = document.getElementById("inputFileStructure")
                                                                    input.click()
                                                                }}
                                                            >
                                                                Selecciona tus archivos
                                                            </Button>
                                                            <input
                                                                id="inputFileStructure"
                                                                type="file"
                                                                multiple
                                                                hidden
                                                                value={""}
                                                                onChange={(e) => {
                                                                    this.filesStructure(e.target.files)
                                                                }}
                                                                disabled={this.state.isSaving}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className='mb-5'>
                                                <Row>
                                                    {project.filesStructure.map((data, index) => {
                                                        let arr = data.split("/")
                                                        let name = arr[arr.length - 1]
                                                        arr = name.split(".")
                                                        let extension = arr[arr.length - 1]
                                                        let posExtension = listExtension.indexOf(extension)
                                                        if (posExtension < 0) {
                                                            extension = "archivo"
                                                        }
                                                        return (
                                                            <Col key={"col " + index} className="col-file">
                                                                <Row className="card-file">
                                                                    <FontAwesomeIcon
                                                                        onClick={(e) => {

                                                                            this.deleteStructure(project.filesStructure[index], index)
                                                                            let projectAux = project
                                                                            let loadingAux = loadingFileStructure
                                                                            projectAux.filesStructure.splice(index, 1)
                                                                            loadingAux.splice(index, 1)
                                                                            this.setState({ project: projectAux, loadingFileStructure: loadingAux })

                                                                        }}
                                                                        className="col-delete"
                                                                        icon={faXmark}
                                                                    />
                                                                    <Col className="col-img">
                                                                        {this.renderImgFileStructure("../../../fileIcon/" + extension + ".png", index)}
                                                                        {loadingFileStructure[index] ?
                                                                            loadingFileStructure[index].processing ?
                                                                                <div className="col-carga">
                                                                                    <CircularProgressbar
                                                                                        value={0}
                                                                                        text={`Cargando`}
                                                                                        styles={
                                                                                            buildStyles({
                                                                                                textColor: "#6c757d",
                                                                                                pathColor: "#6c757d"
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                :
                                                                                loadingFileStructure[index].value === loadingFileStructure[index].total ?
                                                                                    <></>
                                                                                    :
                                                                                    <div className="col-carga">
                                                                                        <CircularProgressbar
                                                                                            value={loadingFileStructure[index].value}
                                                                                            maxValue={loadingFileStructure[index].total}
                                                                                            text={`${Math.round(loadingFileStructure[index].value * 100 / loadingFileStructure[index].total)}%`}
                                                                                            styles={
                                                                                                buildStyles({
                                                                                                    textColor: "#6c757d",
                                                                                                    pathColor: "#6c757d"
                                                                                                })
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </Col>
                                                                    <Col className="col-name">{name}</Col>
                                                                </Row>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {
                                                project.structure !== "" &&
                                                <div className="text-editor" dangerouslySetInnerHTML={{ __html: project.structure }}></div>
                                            }

                                            {
                                                this.state.project.linkVideoStructure && this.state.project.linkVideoStructure !== "" ?
                                                    <>
                                                        {
                                                            linkVideoStructure.map((res, index) => {
                                                                if (res !== "") {
                                                                    let link = res.replace("watch?v=", "embed/")
                                                                    return (
                                                                        <div className="m-2" key={"structure" + index}>
                                                                            <iframe width="384" height="216" src={link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <></>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <></>
                                            }

                                            {
                                                project.filesStructure.length > 0 &&
                                                <div>
                                                    <Row>
                                                        {project.filesStructure.map((data, index) => {
                                                            let arr = data.split("/")
                                                            let name = arr[arr.length - 1]
                                                            arr = name.split(".")
                                                            let extension = arr[arr.length - 1]
                                                            let posExtension = listExtension.indexOf(extension)
                                                            if (posExtension < 0) {
                                                                extension = "archivo"
                                                            }
                                                            return (
                                                                <Col key={"col " + index} className="col-file">
                                                                    <Row className="card-file">
                                                                        <a href={"https://tallervirtualarchivos.s3.amazonaws.com/" + data} target="_blank" rel="noreferrer">
                                                                            <FontAwesomeIcon
                                                                                className="col-view"
                                                                                icon={faEye}
                                                                            />
                                                                        </a>

                                                                        <Col className="col-img">
                                                                            <img width={50} src={"../../../fileIcon/" + extension + ".png"} alt="archivo" />
                                                                        </Col>

                                                                        <Col className="col-name">{name}</Col>
                                                                    </Row>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </div>
                                            }
                                        </>}

                                </div>

                                <div className="container-info">

                                    <div className="sticky">
                                        <h1 className="title">Ejemplos ilustrativos</h1>

                                        <div className="edit">
                                            {isEditExample ?
                                                <>
                                                    {this.state.isSaving ?
                                                        <div style={{ display: "inline-block" }}>
                                                            <Loading disabledAnimation size="sm" />
                                                        </div>
                                                        :
                                                        <FontAwesomeIcon
                                                            icon={faSave}
                                                            onClick={() => {
                                                                if (!this.state.isSaving) {
                                                                    this.setState({ isSaving: true })
                                                                    let projectAux = project
                                                                    projectAux.example = editorRefExample.current.getContent()
                                                                    projectAux.linkVideoExample = this.state.auxLinkVideoExample
                                                                    let isLoading = false
                                                                    this.state.loadingFileExample.forEach(res => {
                                                                        if (res) {
                                                                            if (res.processing) {
                                                                                isLoading = true
                                                                            } else {
                                                                                if (res.value === res.total) {

                                                                                } else {
                                                                                    isLoading = true
                                                                                }
                                                                            }
                                                                        } else {
                                                                            isLoading = true
                                                                        }
                                                                    })
                                                                    if (!isLoading) {
                                                                        let body = {
                                                                            by: "example",
                                                                            files: projectAux.filesExample,
                                                                            text: projectAux.example,
                                                                            idProject: this.props.params.id,
                                                                            linkVideo: this.state.auxLinkVideoExample
                                                                        }
                                                                        this.postProjectDescription(body).then(res => {
                                                                            Swal.fire({
                                                                                icon: 'success',
                                                                                text: 'Ejemplos ilustrativos guardado correctamente!',
                                                                                confirmButtonColor:"#ffc107"
                                                                            })
                                                                            this.setState({ project: projectAux, auxFileExample: [], isEditExample: false, projectAux: false, loadingFileExample: [], parallelUploads3Example: [], isSaving: false })
                                                                        })

                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    }

                                                    <FontAwesomeIcon
                                                        icon={faXmark}
                                                        className="ps-2"
                                                        onClick={() => {
                                                            if (!this.state.isSaving) {
                                                                let projectAux = this.state.projectAux
                                                                projectAux.filesExample = this.state.auxFileExample
                                                                this.setState({ project: projectAux, auxFileExample: [], isEditExample: false, projectAux: false, loadingFileExample: [], parallelUploads3Example: [] })
                                                            }
                                                        }}
                                                    />
                                                </>
                                                :
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    onClick={() => {
                                                        if (!this.state.isSaving) {
                                                            if (!isEditCourseContents && !isEditDescription && !isEditPattern && !isEditStructure) {
                                                                this.setState({ isEditExample: true, projectAux: this.state.project, auxFileExample: [...this.state.project.filesExample], auxLinkVideoExample: this.state.project.linkVideoExample })
                                                            }
                                                        }
                                                    }}
                                                />
                                            }
                                        </div>
                                    </div>

                                    {isEditExample ?
                                        <>
                                            <Editor
                                                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                                onInit={(evt, editor) => {
                                                    let editorRefExampleAux = editorRefExample;
                                                    editorRefExampleAux.current = editor;
                                                    this.setState({ editorRefExample: editorRefExampleAux })
                                                }}
                                                initialValue={project.example}
                                                init={{
                                                    height: 400,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                                                    ],
                                                    toolbar: 'undo redo | blocks | ' +
                                                        'bold italic forecolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                                disabled={this.state.isSaving}
                                            />

                                            <div className='my-3'>
                                                <h4>Link Video (ingresar links separados por comas)</h4>
                                                <FormControl value={this.state.auxLinkVideoExample} onChange={(event) => this.setState({ auxLinkVideoExample: event.target.value })} disabled={this.state.isSaving} />
                                            </div>

                                            <div className='my-3'>
                                                <Row>
                                                    <Col>
                                                        <div
                                                            className="drop-area"
                                                            id="drop-area-example"
                                                            onDragOver={(e) => {
                                                                e.preventDefault()
                                                                let dropArea = document.getElementById("drop-area-example")
                                                                dropArea.querySelector("h4").textContent = "Suelta para subir tus archivos"
                                                                dropArea.classList.add("active")
                                                            }}
                                                            onDragLeave={(e) => {
                                                                e.preventDefault()
                                                                let dropArea = document.getElementById("drop-area-example")
                                                                dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                                dropArea.classList.remove("active")
                                                            }}
                                                            onDrop={(e) => {
                                                                if (!this.state.isSaving) {
                                                                    e.preventDefault()
                                                                    this.filesExample(e.dataTransfer.files)
                                                                    let dropArea = document.getElementById("drop-area-example")
                                                                    dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                                    dropArea.classList.remove("active")
                                                                }

                                                            }}
                                                        >
                                                            <h4 id="h4-example">Arrastra aquí tus archivos</h4>
                                                            <span>O</span>
                                                            <Button
                                                                className="mt-2"
                                                                variant="secondary"
                                                                onClick={(e) => {
                                                                    let input = document.getElementById("inputFileExample")
                                                                    input.click()
                                                                }}
                                                            >
                                                                Selecciona tus archivos
                                                            </Button>
                                                            <input
                                                                id="inputFileExample"
                                                                type="file"
                                                                multiple
                                                                hidden
                                                                value={""}
                                                                onChange={(e) => {
                                                                    this.filesExample(e.target.files)
                                                                }}
                                                                disabled={this.state.isSaving}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className='mb-5'>
                                                <Row>
                                                    {project.filesExample.map((data, index) => {
                                                        let arr = data.split("/")
                                                        let name = arr[arr.length - 1]
                                                        arr = name.split(".")
                                                        let extension = arr[arr.length - 1]
                                                        let posExtension = listExtension.indexOf(extension)
                                                        if (posExtension < 0) {
                                                            extension = "archivo"
                                                        }
                                                        return (
                                                            <Col key={"col " + index} className="col-file">
                                                                <Row className="card-file">
                                                                    <FontAwesomeIcon
                                                                        onClick={(e) => {

                                                                            this.deleteExample(project.filesExample[index], index)
                                                                            let projectAux = project
                                                                            let loadingAux = loadingFileExample
                                                                            projectAux.filesExample.splice(index, 1)
                                                                            loadingAux.splice(index, 1)
                                                                            this.setState({ project: projectAux, loadingFileExample: loadingAux })

                                                                        }}
                                                                        className="col-delete"
                                                                        icon={faXmark}
                                                                    />
                                                                    <Col className="col-img">
                                                                        {this.renderImgFileExample("../../../fileIcon/" + extension + ".png", index)}
                                                                        {loadingFileExample[index] ?
                                                                            loadingFileExample[index].processing ?
                                                                                <div className="col-carga">
                                                                                    <CircularProgressbar
                                                                                        value={0}
                                                                                        text={`Cargando`}
                                                                                        styles={
                                                                                            buildStyles({
                                                                                                textColor: "#6c757d",
                                                                                                pathColor: "#6c757d"
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                :
                                                                                loadingFileExample[index].value === loadingFileExample[index].total ?
                                                                                    <></>
                                                                                    :
                                                                                    <div className="col-carga">
                                                                                        <CircularProgressbar
                                                                                            value={loadingFileExample[index].value}
                                                                                            maxValue={loadingFileExample[index].total}
                                                                                            text={`${Math.round(loadingFileExample[index].value * 100 / loadingFileExample[index].total)}%`}
                                                                                            styles={
                                                                                                buildStyles({
                                                                                                    textColor: "#6c757d",
                                                                                                    pathColor: "#6c757d"
                                                                                                })
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </Col>
                                                                    <Col className="col-name">{name}</Col>
                                                                </Row>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {
                                                project.example !== "" &&
                                                <div className="text-editor" dangerouslySetInnerHTML={{ __html: project.example }}></div>
                                            }

                                            {
                                                this.state.project.linkVideoExample && this.state.project.linkVideoExample !== "" ?
                                                    <>
                                                        {
                                                            linkVideoExample.map((res, index) => {

                                                                if (res !== "") {
                                                                    let link = res.replace("watch?v=", "embed/")
                                                                    return (
                                                                        <div className="m-2" key={"example" + index}>
                                                                            <iframe width="384" height="216" src={link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <></>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <></>
                                            }

                                            {
                                                project.filesExample.length > 0 &&
                                                <div>
                                                    <Row>
                                                        {project.filesExample.map((data, index) => {
                                                            let arr = data.split("/")
                                                            let name = arr[arr.length - 1]
                                                            arr = name.split(".")
                                                            let extension = arr[arr.length - 1]
                                                            let posExtension = listExtension.indexOf(extension)
                                                            if (posExtension < 0) {
                                                                extension = "archivo"
                                                            }
                                                            return (
                                                                <Col key={"col " + index} className="col-file">
                                                                    <Row className="card-file">
                                                                        <a href={"https://tallervirtualarchivos.s3.amazonaws.com/" + data} target="_blank" rel="noreferrer">
                                                                            <FontAwesomeIcon
                                                                                className="col-view"
                                                                                icon={faEye}
                                                                            />
                                                                        </a>

                                                                        <Col className="col-img">
                                                                            <img width={50} src={"../../../fileIcon/" + extension + ".png"} alt="archivo" />
                                                                        </Col>

                                                                        <Col className="col-name">{name}</Col>
                                                                    </Row>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </div>
                                            }
                                        </>}

                                </div>

                                <div className="container-info">

                                    <div className="sticky">
                                        <h1 className="title">Pauta de evaluación</h1>

                                        <div className="edit">
                                            {isEditPattern ?
                                                <>
                                                    {this.state.isSaving ?
                                                        <div style={{ display: "inline-block" }}>
                                                            <Loading disabledAnimation size="sm" />
                                                        </div>
                                                        :
                                                        <FontAwesomeIcon
                                                            icon={faSave}
                                                            onClick={() => {
                                                                if (!this.state.isSaving) {
                                                                    this.setState({ isSaving: true })
                                                                    let projectAux = project
                                                                    projectAux.pattern = editorRefPattern.current.getContent()
                                                                    projectAux.linkVideoPattern = this.state.auxLinkVideoPattern
                                                                    let isLoading = false
                                                                    this.state.loadingFilePattern.forEach(res => {
                                                                        if (res) {
                                                                            if (res.processing) {
                                                                                isLoading = true
                                                                            } else {
                                                                                if (res.value === res.total) {

                                                                                } else {
                                                                                    isLoading = true
                                                                                }
                                                                            }
                                                                        } else {
                                                                            isLoading = true
                                                                        }
                                                                    })
                                                                    if (!isLoading) {
                                                                        let body = {
                                                                            by: "pattern",
                                                                            files: projectAux.filesPattern,
                                                                            text: projectAux.pattern,
                                                                            idProject: this.props.params.id,
                                                                            linkVideo: this.state.auxLinkVideoPattern
                                                                        }
                                                                        this.postProjectDescription(body).then(res => {
                                                                            Swal.fire({
                                                                                icon: 'success',
                                                                                text: 'Pauta de evaluacion guardada correctamente!',
                                                                                confirmButtonColor:"#ffc107"
                                                                            })
                                                                            this.setState({ project: projectAux, auxFilePattern: [], isEditPattern: false, projectAux: false, loadingFilePattern: [], parallelUploads3Pattern: [], isSaving: false })
                                                                        })

                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    }

                                                    <FontAwesomeIcon
                                                        icon={faXmark}
                                                        className="ps-2"
                                                        onClick={() => {
                                                            if (!this.state.isSaving) {
                                                                let projectAux = this.state.projectAux
                                                                projectAux.filesPattern = this.state.auxFilePattern
                                                                this.setState({ project: projectAux, auxFilePattern: [], isEditPattern: false, projectAux: false, loadingFilePattern: [], parallelUploads3Pattern: [] })
                                                            }
                                                        }}
                                                    />
                                                </>
                                                :
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    onClick={() => {
                                                        if (!this.state.isSaving) {
                                                            if (!isEditCourseContents && !isEditDescription && !isEditExample && !isEditStructure) {
                                                                this.setState({ isEditPattern: true, projectAux: this.state.project, auxFilePattern: [...this.state.project.filesPattern], auxLinkVideoPattern: this.state.project.linkVideoPattern })
                                                            }
                                                        }
                                                    }}
                                                />
                                            }
                                        </div>
                                    </div>

                                    {isEditPattern ?
                                        <>
                                            <Editor
                                                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                                onInit={(evt, editor) => {
                                                    let editorRefPatternAux = editorRefPattern;
                                                    editorRefPatternAux.current = editor;
                                                    this.setState({ editorRefPattern: editorRefPatternAux })
                                                }}
                                                initialValue={project.pattern}
                                                init={{
                                                    height: 400,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                                                    ],
                                                    toolbar: 'undo redo | blocks | ' +
                                                        'bold italic forecolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                                disabled={this.state.isSaving}
                                            />

                                            <div className='my-3'>
                                                <h4>Link Video (ingresar links separados por comas)</h4>
                                                <FormControl value={this.state.auxLinkVideoPattern} onChange={(event) => this.setState({ auxLinkVideoPattern: event.target.value })} disabled={this.state.isSaving} />
                                            </div>

                                            <div className='my-3'>
                                                <Row>
                                                    <Col>
                                                        <div
                                                            className="drop-area"
                                                            id="drop-area-pattern"
                                                            onDragOver={(e) => {
                                                                e.preventDefault()
                                                                let dropArea = document.getElementById("drop-area-pattern")
                                                                dropArea.querySelector("h4").textContent = "Suelta para subir tus archivos"
                                                                dropArea.classList.add("active")
                                                            }}
                                                            onDragLeave={(e) => {
                                                                e.preventDefault()
                                                                let dropArea = document.getElementById("drop-area-pattern")
                                                                dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                                dropArea.classList.remove("active")
                                                            }}
                                                            onDrop={(e) => {
                                                                if (!this.state.isSaving) {
                                                                    e.preventDefault()
                                                                    this.filesPattern(e.dataTransfer.files)
                                                                    let dropArea = document.getElementById("drop-area-pattern")
                                                                    dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                                    dropArea.classList.remove("active")
                                                                }

                                                            }}
                                                        >
                                                            <h4 id="h4-pattern">Arrastra aquí tus archivos</h4>
                                                            <span>O</span>
                                                            <Button
                                                                className="mt-2"
                                                                variant="secondary"
                                                                onClick={(e) => {
                                                                    let input = document.getElementById("inputFilePattern")
                                                                    input.click()
                                                                }}
                                                            >
                                                                Selecciona tus archivos
                                                            </Button>
                                                            <input
                                                                id="inputFilePattern"
                                                                type="file"
                                                                multiple
                                                                hidden
                                                                value={""}
                                                                onChange={(e) => {
                                                                    this.filesPattern(e.target.files)
                                                                }}
                                                                disabled={this.state.isSaving}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className='mb-5'>
                                                <Row>
                                                    {project.filesPattern.map((data, index) => {
                                                        let arr = data.split("/")
                                                        let name = arr[arr.length - 1]
                                                        arr = name.split(".")
                                                        let extension = arr[arr.length - 1]
                                                        let posExtension = listExtension.indexOf(extension)
                                                        if (posExtension < 0) {
                                                            extension = "archivo"
                                                        }
                                                        return (
                                                            <Col key={"col " + index} className="col-file">
                                                                <Row className="card-file">
                                                                    <FontAwesomeIcon
                                                                        onClick={(e) => {

                                                                            this.deletePattern(project.filesPattern[index], index)
                                                                            let projectAux = project
                                                                            let loadingAux = loadingFilePattern
                                                                            projectAux.filesPattern.splice(index, 1)
                                                                            loadingAux.splice(index, 1)
                                                                            this.setState({ project: projectAux, loadingFilePattern: loadingAux })

                                                                        }}
                                                                        className="col-delete"
                                                                        icon={faXmark}
                                                                    />
                                                                    <Col className="col-img">
                                                                        {this.renderImgFilePattern("../../../fileIcon/" + extension + ".png", index)}
                                                                        {loadingFilePattern[index] ?
                                                                            loadingFilePattern[index].processing ?
                                                                                <div className="col-carga">
                                                                                    <CircularProgressbar
                                                                                        value={0}
                                                                                        text={`Cargando`}
                                                                                        styles={
                                                                                            buildStyles({
                                                                                                textColor: "#6c757d",
                                                                                                pathColor: "#6c757d"
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                :
                                                                                loadingFilePattern[index].value === loadingFilePattern[index].total ?
                                                                                    <></>
                                                                                    :
                                                                                    <div className="col-carga">
                                                                                        <CircularProgressbar
                                                                                            value={loadingFilePattern[index].value}
                                                                                            maxValue={loadingFilePattern[index].total}
                                                                                            text={`${Math.round(loadingFilePattern[index].value * 100 / loadingFilePattern[index].total)}%`}
                                                                                            styles={
                                                                                                buildStyles({
                                                                                                    textColor: "#6c757d",
                                                                                                    pathColor: "#6c757d"
                                                                                                })
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </Col>
                                                                    <Col className="col-name">{name}</Col>
                                                                </Row>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {
                                                project.pattern !== "" &&
                                                <div className="text-editor" dangerouslySetInnerHTML={{ __html: project.pattern }}></div>
                                            }

                                            {
                                                this.state.project.linkVideoPattern && this.state.project.linkVideoPattern !== "" ?
                                                    <>
                                                        {
                                                            linkVideoPattern.map((res, index) => {
                                                                if (res !== "") {
                                                                    let link = res.replace("watch?v=", "embed/")
                                                                    return (
                                                                        <div className="m-2" key={"pattern" + index}>
                                                                            <iframe width="384" height="216" src={link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <></>
                                                                    )
                                                                }

                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <></>
                                            }

                                            {
                                                project.filesPattern.length > 0 &&
                                                <div>
                                                    <Row>
                                                        {project.filesPattern.map((data, index) => {
                                                            let arr = data.split("/")
                                                            let name = arr[arr.length - 1]
                                                            arr = name.split(".")
                                                            let extension = arr[arr.length - 1]
                                                            let posExtension = listExtension.indexOf(extension)
                                                            if (posExtension < 0) {
                                                                extension = "archivo"
                                                            }
                                                            return (
                                                                <Col key={"col " + index} className="col-file">
                                                                    <Row className="card-file">
                                                                        <a href={"https://tallervirtualarchivos.s3.amazonaws.com/" + data} target="_blank" rel="noreferrer">
                                                                            <FontAwesomeIcon
                                                                                className="col-view"
                                                                                icon={faEye}
                                                                            />
                                                                        </a>

                                                                        <Col className="col-img">
                                                                            <img width={50} src={"../../../fileIcon/" + extension + ".png"} alt="archivo" />
                                                                        </Col>

                                                                        <Col className="col-name">{name}</Col>
                                                                    </Row>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </div>
                                            }
                                        </>}

                                </div>

                                <div className="container-info">

                                    <div className="sticky">
                                        <h1 className="title">Contenidos del curso</h1>
                                        <div className="edit">
                                            {isEditCourseContents ?
                                                <>
                                                    {this.state.isSaving ?
                                                        <div style={{ display: "inline-block" }}>
                                                            <Loading disabledAnimation size="sm" />
                                                        </div>
                                                        :
                                                        <FontAwesomeIcon
                                                            icon={faSave}
                                                            onClick={() => {
                                                                if (!this.state.isSaving) {
                                                                    this.setState({ isSaving: true })
                                                                    let projectAux = project
                                                                    projectAux.courseContents = editorRefCourseContents.current.getContent()
                                                                    projectAux.linkVideoCourseContents = this.state.auxLinkVideoCourseContents
                                                                    let isLoading = false
                                                                    this.state.loadingFileCourseContents.forEach(res => {
                                                                        if (res) {
                                                                            if (res.processing) {
                                                                                isLoading = true
                                                                            } else {
                                                                                if (res.value === res.total) {

                                                                                } else {
                                                                                    isLoading = true
                                                                                }
                                                                            }
                                                                        } else {
                                                                            isLoading = true
                                                                        }
                                                                    })
                                                                    if (!isLoading) {
                                                                        let body = {
                                                                            by: "courseContents",
                                                                            files: projectAux.filesCourseContents,
                                                                            text: projectAux.courseContents,
                                                                            idProject: this.props.params.id,
                                                                            linkVideo: this.state.auxLinkVideoCourseContents
                                                                        }
                                                                        this.postProjectDescription(body).then(res => {
                                                                            Swal.fire({
                                                                                icon: 'success',
                                                                                text: 'Contenidos del curso guardado correctamente!',
                                                                                confirmButtonColor:"#ffc107"
                                                                            })
                                                                            this.setState({ project: projectAux, auxFileCourseContents: [], isEditCourseContents: false, projectAux: false, loadingFileCourseContents: [], parallelUploads3CourseContents: [], isSaving: false })
                                                                        })

                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    }

                                                    <FontAwesomeIcon
                                                        icon={faXmark}
                                                        className="ps-2"
                                                        onClick={() => {
                                                            if (!this.state.isSaving) {
                                                                let projectAux = this.state.projectAux
                                                                projectAux.filesCourseContents = this.state.auxFileCourseContents
                                                                this.setState({ project: projectAux, auxFileCourseContents: [], isEditCourseContents: false, projectAux: false, loadingFileCourseContents: [], parallelUploads3CourseContents: [] })
                                                            }
                                                        }}
                                                    />
                                                </>
                                                :
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    onClick={() => {
                                                        if (!this.state.isSaving) {
                                                            if (!isEditDescription && !isEditExample && !isEditPattern && !isEditStructure) {
                                                                this.setState({ isEditCourseContents: true, projectAux: this.state.project, auxFileCourseContents: [...this.state.project.filesCourseContents], auxLinkVideoCourseContents: this.state.project.linkVideoCourseContents })
                                                            }
                                                        }
                                                    }}
                                                />
                                            }
                                        </div>
                                    </div>

                                    {isEditCourseContents ?
                                        <>
                                            <Editor
                                                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                                onInit={(evt, editor) => {
                                                    let editorRefCourseContentsAux = editorRefCourseContents;
                                                    editorRefCourseContentsAux.current = editor;
                                                    this.setState({ editorRefCourseContents: editorRefCourseContentsAux })
                                                }}
                                                initialValue={project.courseContents}
                                                init={{
                                                    height: 400,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                                                    ],
                                                    toolbar: 'undo redo | blocks | ' +
                                                        'bold italic forecolor | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                }}
                                                disabled={this.state.isSaving}
                                            />

                                            <div className='my-3'>
                                                <h4>Link Video (ingresar links separados por comas)</h4>
                                                <FormControl value={this.state.auxLinkVideoCourseContents} onChange={(event) => this.setState({ auxLinkVideoCourseContents: event.target.value })} disabled={this.state.isSaving} />
                                            </div>

                                            <div className='my-3'>
                                                <Row>
                                                    <Col>
                                                        <div
                                                            className="drop-area"
                                                            id="drop-area-courseContents"
                                                            onDragOver={(e) => {
                                                                e.preventDefault()
                                                                let dropArea = document.getElementById("drop-area-courseContents")
                                                                dropArea.querySelector("h4").textContent = "Suelta para subir tus archivos"
                                                                dropArea.classList.add("active")
                                                            }}
                                                            onDragLeave={(e) => {
                                                                e.preventDefault()
                                                                let dropArea = document.getElementById("drop-area-courseContents")
                                                                dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                                dropArea.classList.remove("active")
                                                            }}
                                                            onDrop={(e) => {
                                                                if (!this.state.isSaving) {
                                                                    e.preventDefault()
                                                                    this.filesCourseContents(e.dataTransfer.files)
                                                                    let dropArea = document.getElementById("drop-area-courseContents")
                                                                    dropArea.querySelector("h4").textContent = "Arrastra aquí tus archivos"
                                                                    dropArea.classList.remove("active")
                                                                }

                                                            }}
                                                        >
                                                            <h4 id="h4-courseContents">Arrastra aquí tus archivos</h4>
                                                            <span>O</span>
                                                            <Button
                                                                className="mt-2"
                                                                variant="secondary"
                                                                onClick={(e) => {
                                                                    let input = document.getElementById("inputFileCourseContents")
                                                                    input.click()
                                                                }}
                                                            >
                                                                Selecciona tus archivos
                                                            </Button>
                                                            <input
                                                                id="inputFileCourseContents"
                                                                type="file"
                                                                multiple
                                                                hidden
                                                                value={""}
                                                                onChange={(e) => {
                                                                    this.filesCourseContents(e.target.files)
                                                                }}
                                                                disabled={this.state.isSaving}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className='mb-5'>
                                                <Row>
                                                    {project.filesCourseContents.map((data, index) => {
                                                        let arr = data.split("/")
                                                        let name = arr[arr.length - 1]
                                                        arr = name.split(".")
                                                        let extension = arr[arr.length - 1]
                                                        let posExtension = listExtension.indexOf(extension)
                                                        if (posExtension < 0) {
                                                            extension = "archivo"
                                                        }
                                                        return (
                                                            <Col key={"col " + index} className="col-file">
                                                                <Row className="card-file">
                                                                    <FontAwesomeIcon
                                                                        onClick={(e) => {

                                                                            this.deleteCourseContents(project.filesCourseContents[index], index)
                                                                            let projectAux = project
                                                                            let loadingAux = loadingFileCourseContents
                                                                            projectAux.filesCourseContents.splice(index, 1)
                                                                            loadingAux.splice(index, 1)
                                                                            this.setState({ project: projectAux, loadingFileCourseContents: loadingAux })

                                                                        }}
                                                                        className="col-delete"
                                                                        icon={faXmark}
                                                                    />
                                                                    <Col className="col-img">
                                                                        {this.renderImgFileCourseContents("../../../fileIcon/" + extension + ".png", index)}
                                                                        {loadingFileCourseContents[index] ?
                                                                            loadingFileCourseContents[index].processing ?
                                                                                <div className="col-carga">
                                                                                    <CircularProgressbar
                                                                                        value={0}
                                                                                        text={`Cargando`}
                                                                                        styles={
                                                                                            buildStyles({
                                                                                                textColor: "#6c757d",
                                                                                                pathColor: "#6c757d"
                                                                                            })
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                :
                                                                                loadingFileCourseContents[index].value === loadingFileCourseContents[index].total ?
                                                                                    <></>
                                                                                    :
                                                                                    <div className="col-carga">
                                                                                        <CircularProgressbar
                                                                                            value={loadingFileCourseContents[index].value}
                                                                                            maxValue={loadingFileCourseContents[index].total}
                                                                                            text={`${Math.round(loadingFileCourseContents[index].value * 100 / loadingFileCourseContents[index].total)}%`}
                                                                                            styles={
                                                                                                buildStyles({
                                                                                                    textColor: "#6c757d",
                                                                                                    pathColor: "#6c757d"
                                                                                                })
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </Col>
                                                                    <Col className="col-name">{name}</Col>
                                                                </Row>
                                                            </Col>
                                                        )
                                                    })}
                                                </Row>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {
                                                project.courseContents !== "" &&
                                                <div className="text-editor" dangerouslySetInnerHTML={{ __html: project.courseContents }}></div>
                                            }

                                            {
                                                this.state.project.linkVideoCourseContents && this.state.project.linkVideoCourseContents !== "" ?
                                                    <>
                                                        {
                                                            linkVideoCourseContents.map((res, index) => {
                                                                if (res !== "") {
                                                                    let link = res.replace("watch?v=", "embed/")
                                                                    return (
                                                                        <div className="m-2" key={"courseContents" + index}>
                                                                            <iframe width="384" height="216" src={link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <></>
                                                                    )
                                                                }

                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <></>
                                            }

                                            {
                                                project.filesCourseContents.length > 0 &&
                                                <div>
                                                    <Row>
                                                        {project.filesCourseContents.map((data, index) => {
                                                            let arr = data.split("/")
                                                            let name = arr[arr.length - 1]
                                                            arr = name.split(".")
                                                            let extension = arr[arr.length - 1]
                                                            let posExtension = listExtension.indexOf(extension)
                                                            if (posExtension < 0) {
                                                                extension = "archivo"
                                                            }
                                                            return (
                                                                <Col key={"col " + index} className="col-file">
                                                                    <Row className="card-file">
                                                                        <a href={"https://tallervirtualarchivos.s3.amazonaws.com/" + data} target="_blank" rel="noreferrer">
                                                                            <FontAwesomeIcon
                                                                                className="col-view"
                                                                                icon={faEye}
                                                                            />
                                                                        </a>

                                                                        <Col className="col-img">
                                                                            <img width={50} src={"../../../fileIcon/" + extension + ".png"} alt="archivo" />
                                                                        </Col>

                                                                        <Col className="col-name">{name}</Col>
                                                                    </Row>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </div>
                                            }
                                        </>}

                                </div>
                            </div>
                        }

                    </Col>

                    <Col className="calendar">
                        <div className="container-info">

                            <h1 className="title">Calendario <Button variant="secondary" onClick={this.handleShow}>Agregar evento</Button></h1>
                            <Row className="justify-content-center">
                                <Col style={{ flex: "0 0 auto", width: "348px" }} className="m-2">

                                    <Calendar
                                        onChange={this.onChange}
                                        value={this.state.value}
                                    />
                                </Col>
                                <Col className="m-2">
                                    <h4 style={{ color: "rgb(var(--color-1))" }}>Eventos del {this.eventDay()}</h4>
                                    {
                                        isLoadingNotes ?
                                            <div style={{ height: "100%", padding: "8px" }}>
                                                <Loading disabledAnimation />
                                            </div>
                                            :
                                            viewNotes.length === 0 ?
                                                <div className="note">
                                                    <p>- Sin eventos</p>
                                                </div>
                                                :
                                                <div>
                                                    {viewNotes.map((res, index) => {
                                                        return (
                                                            <div className="note" key={index}>
                                                                <p>Nombre: {res.name}</p>
                                                                <p>Hora termino: {res.date.split("T")[1].split("Z")[0]}</p>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Modal show={this.state.show} centered style={{ color: "rgb(var(--color-22))" }}>
                    <Modal.Header>
                        <Modal.Title>Nuevo evento</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>Fecha termino:</label>
                        <Form.Control disabled={this.state.isLoadingNote} className="mb-2" type="datetime-local" onChange={(e) => { this.setState({ newDateEvent: e.target.value + ":59" }) }} />
                        <label>Nombre:</label>
                        <Form.Control disabled={this.state.isLoadingNote} className="mb-2" value={this.state.newNameEvent} onChange={(e) => { this.setState({ newNameEvent: e.target.value }) }} />
                        <label>Descripcion:</label>
                        <FormControl disabled={this.state.isLoadingNote} as="textarea" value={this.state.newDescriptionEvent} onChange={(e) => { this.setState({ newDescriptionEvent: e.target.value }) }} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" disabled={this.state.isLoadingNote} onClick={this.handleClose}>Cancelar</Button>
                        {
                            !this.state.isLoadingNote ?
                                <Button variant="warning" onClick={this.handleClick}>Enviar</Button>
                                :
                                <Button variant="warning" disabled><Loading disabledAnimation /></Button>
                        }

                    </Modal.Footer>

                </Modal>
            </div>
        )
    }
}

export default withParams(BriefTeacher);